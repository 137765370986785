import React, { Component } from 'react'
import Dictionary from './Dictionary'
import { Link } from 'react-router-dom'
import UserSvg from '../svg/UserSvg'
import MessageSvg from '../svg/MessageSvg'
import ChangeLang from './ChangeLang'
import LogOutSvg from '../svg/LogOutSvg'
import { connect } from 'react-redux'
import {withHooks} from "../../actions/withHooks";
import { insertNewData, logOutAction } from '../../actions/MainAction'
import MenuBarSvg from '../svg/MenuBarSvg'
import MenuCloseSvg from '../svg/MenuCloseSvg'
import { changeStateValue } from '../../redux/MainReducer'
export class Headerbar extends Component {
  logOutBtn(){
    this.props.insertNewData(`changeLoginStatus/${this.props.user?.id}`, {})
    this.props.logOutAction()
    .then(resp=>{
      this.props.navigate("/")
    })
  }
  render() {
    const {unreadMsgCount, navBtn, user} = this.props;
    let result = unreadMsgCount.reduce(function (r, a) {
           
      r[a.message_group_id] = r[a.message_group_id] || [];
      r[a.message_group_id].push(a);
      return r;
  }, {});
  const count = Object.values(result);
    return (
      <div className='h-[61px] p-[10px] bg-white rounded-[30px] flex items-center gap-[20px] headerbar'>
        {
          user?.role == "student" || user?.role == "teacher" || user?.role == "head_teacher" || user?.role == "speaking_teacher" || user?.role == "parent"?
          <Dictionary />:null
        }
        
        <div className='flex items-center gap-[15px]'>
          <Link to={'/profile'}>
            {
              !user?.avatar_full_url || user?.avatar_full_url ===""? <UserSvg />: <img className='w-[30px] h-[30px] rounded-full' src={user?.avatar_full_url} />
            }
            
          </Link>
          <Link to={'/messages'} className='relative'>
            {
              count.length !==0?
               <span className='absolute top-0 right-0 mt-[-12px] bg-blue inline-flex min-w-[17px] h-[17px] mr-[-7px] pt-[0.3px] pl-[4px] pr-[4px] items-center justify-center text-[11px] text-white rounded-full'>{count.length}</span>:null
            }
           
            <MessageSvg />
          </Link>
          <button onClick={this.logOutBtn.bind(this)}>
            <LogOutSvg />
          </button>
          <ChangeLang />
          <label htmlFor='navBtnCheckox' onClick={()=>this.props.changeStateValue({name:'navBtn', value:false})} className='hidden max-[1100px]:inline-flex items-center w-[30px] h-[30px]'>
            {navBtn? <MenuBarSvg />: <MenuCloseSvg /> }
          </label>
        </div>
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
  unreadMsgCount: state.Data.unreadMsgCount,
  navBtn: state.Data.navBtn,
  user: state.Data.user,
});
const mapDispatchToProps = {logOutAction, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Headerbar))
