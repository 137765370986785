import React, { Component } from 'react'
import { connect } from 'react-redux';
import { changeStateValue, resetStateValue } from '../../../redux/MainReducer';
import Input from '../../../components/modals/components/Input';
import { getCourses, getLessons } from '../../../actions/MainAction';
import { getDropValue } from '../../../actions/getRole';
import DropSearch from '../../../components/DropSearch';
import { mixinAlert } from '../../../actions/SweetAlerts';
import { insertDataWithParams, getExamData } from '../../../actions/MainAction';
import { withHooks } from '../../../actions/withHooks';
export class ExamInfo extends Component {
    state={
        visible:false,
        btnDisable: false
    }
    componentDidMount(){
        this.props.resetStateValue({name:'adminExamData'})
        this.props.getCourses();
        if(this.props.params.id){
            this.props.getExamData(this.props.params.id)
            .then(resp=>{
                // console.log(resp)
                this.props.changeStateValue({name:"adminExamData", value:resp})
                this.props.getLessons(resp?.course_id)
            })
        }else{
            this.setState({visible:true})
        }
    }
    selectCourse=(data)=>{
        this.props.changeStateValue({name:"adminExamData.course_id", value:data})
        this.props.getLessons(data)
    }
    onClickBtn=(data)=>{
        if(data?.title !== "" && data?.course_id !== ""  && data?.lesson_id !== ""  && data?.duration_minutes !== ""  && data?.retake_minutes !== ""  && data?.retake_time !== "" && data?.points !== ""){
            this.setState({btnDisable:true})
            let formData = new FormData();
            let url = 'exams';

            for(const property in data){
                if(data[property]){
                    formData.append(property, data[property])
                }
                
            }
            if(data.id != ''){
                url=`exams/${data.id}`;
                formData.append("_method", "put");
            }
            this.props.insertDataWithParams(url, formData)
            .then(resp=>{
                this.setState({btnDisable:false});
                if(resp.msg === "success"){
                    this.setState({visible:false})
                    mixinAlert("success", `${data?.type == 0? "Exam":"HW"} ${data?.id !=""? "edited":"added"} successfully.`);
                    if(resp?.data?.data){
                         this.props.navigate(`/admin-exams/${resp?.data?.data?.id}`);
                        this.props.changeStateValue({name:"adminExamData", value:resp?.data?.data})
                    }
                   
                }else{
                    mixinAlert("error", "Something went wrong.")
                }
            })
        }else{
            mixinAlert("info", "Please, fill the all required fields.")
        }
    }

    changeExamType=(type)=>{
        this.props.changeStateValue({
            name:"adminExamData.exam_type",
            value: type
        })
    }
  render() {
    const {adminExamData, studentCourses, lessons} = this.props;
    const {visible, btnDisable} = this.state;
    return (
      <div className='bg-white mt-[20px] rounded-[12px] p-[20px] shadow'>
        <h2 onClick={()=>this.setState({visible: !visible})} className='text-[22px] font-semibold txt-blue cursor-pointer'>{adminExamData?.type == 0? "Exam":"Homework"} Details{adminExamData?.id !== ""? `: ${adminExamData?.title}`:''}</h2>
        {
            visible?
            <>
            {
                adminExamData?.id == ""?
                <div className='mt-[20px] mb-[20px] flex items-center gap-[20px]'>
                    <button onClick={this.changeExamType.bind(this, "normal")} className={`p-[4px_6px] text-[12px]  font-regular rounded-[5px] ${adminExamData?.exam_type == "normal"? "bg-blue text-white":"bg-white txt-blue border border-[var(--blue)]"}`}>Normal</button>
                    <button onClick={this.changeExamType.bind(this, "pooling_standart")} className={`p-[4px_6px] text-[12px]  font-regular rounded-[5px] ${adminExamData?.exam_type == "pooling_standart"? "bg-blue text-white":"bg-white txt-blue border border-[var(--blue)]"}`}>Pooling</button>
                </div>:null
            }
            
            <div className='grid grid-full-320 gap-[30px] items-start'>
                <Input  required name="adminExamData.title"  value={adminExamData?.title}  title={"Title"} />
                <DropSearch selectData={this.selectCourse.bind(this)} required value={adminExamData.course_id} placeholder="Select a Course" options={getDropValue(studentCourses, "title","id")} title="Select a Course" />
                <DropSearch name="adminExamData.lesson_id" required value={adminExamData.lesson_id} placeholder="Select a Lesson" options={getDropValue(lessons, "title","id")} title="Select a Lesson" />
                {
                    +adminExamData?.type ===0?
                    <>
                        <Input  required name="adminExamData.duration_minutes"  value={adminExamData?.duration_minutes} type="number" title={"Duration Minutes"} />
                        <Input  required name="adminExamData.retake_minutes"  value={adminExamData?.retake_minutes} type="number" title={"Retike Minutes"} />
                        <Input  required name="adminExamData.retake_time"  value={adminExamData?.retake_time} type="number" title={"Retike Time"} />
                    </>:null
                }
                <Input  required name="adminExamData.points"  value={adminExamData?.points} type="number" title={"Points"} />
                <Input name="adminExamData.tooltip"  value={adminExamData?.tooltip} type="text" title={"Tooltip"} />
            </div>
            <div className='flex justify-end mt-[20px]'>
                <button disabled={btnDisable} onClick={this.onClickBtn.bind(this, adminExamData)} className='bg-blue text-white text-[14px] font-bold w-[80px] h-[35px] rounded-[6px]'>Save</button>
            </div>
            </>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state)=>({
    adminExamData: state.Data.adminExamData,
    studentCourses: state.Data.studentCourses,
    lessons: state.Data.lessons,
});
const mapDispatchToProps = {changeStateValue, resetStateValue, getCourses, getLessons, insertDataWithParams, getExamData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamInfo))
