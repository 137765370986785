import React, { Component } from 'react'
import Header from '../../components/header/Header'
import HeaderButtons from '../../components/HeaderButtons'
import { connect } from 'react-redux';
import { getCompanies } from '../../actions/AdminActions';
import Card1 from '../../components/cards/Card1';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import { changeStateValue } from '../../redux/MainReducer';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { deleteData } from '../../actions/MainAction';
export class AdminCompanies extends Component {
    state={
        page:1,
        search:''
    }
    addBtn=(data="")=>{
        this.props.changeStateValue({
            name:'adminModalData',
            value: {
                position: true,
                id:data?.id? data?.id:'',
                name:data?.name? data?.name:'',
                country:data?.country? data?.country:'',
                city:data?.city? data?.city:'',
                afterCallFunction:this.props.getCompanies,
                afterCallParams:[this.state.page, this.state.search],
                requireds:["name", "country", "city"],
                url: data?.id? `companies/${data?.id}`:'companies',
                modalTitle: data?.id? `Edit company`:'Add company',
            }
        });
    }
    componentDidMount(){
        this.props.getCompanies(2, '')
    }
    loadMore=(page)=>{
        this.setState({page:page});
        this.props.getCompanies(page, this.state.search)
    }
    searchData=(search)=>{
        this.setState({search:search});
        this.props.getCompanies(this.state.page, search)
    }
    onDeleteBtn=(data)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!", "Yes, delete it")
        .then(alertResp=>{
            if(alertResp === "success"){
            this.props.deleteData(`companies/${data?.id}`)
            .then(resp=>{
                if(resp == "success"){
                    this.props.getCompanies(this.state.page, this.state.search)
                    mixinAlert("success", "Data deleted successfully")
                }else{
                    mixinAlert("error", "Something went wrong")
                }
            })
            }
        })
    }
  render() {
    const {adminCompanies} = this.props;

    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="Companies" />
            <div className='mt-[30px] flex justify-end items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={()=>this.addBtn()} buttons={[{title: "Add a company", id:'0'}]} selected={0} />
            </div>
            <div className='flex justify-center'>
                  <Search largWidth={true} onSearchData={this.searchData.bind(this)} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    adminCompanies?.map((data, i)=>{
                        return(
                            <Card1
                                key={i}
                                title={data?.name}
                                name={data?.user?.full_name}
                                statusBisible={false}
                                status={1}
                                onCLickBtn={this.addBtn.bind(this, data)}
                                adminDelete={true}
                                onDeleteBtn={this.onDeleteBtn.bind(this, data)}
                                titleLink={`/audit-company/${data?.id}/${data?.name}`}
                            />
                        )
                    })
                }
            </div>
            {
                adminCompanies?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={adminCompanies?.length} />:null
            }
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
    adminCompanies: state.Data.adminCompanies
});
const mapDispatchToProps = {getCompanies, changeStateValue, deleteData}
export default connect(mapStateToProps, mapDispatchToProps)(AdminCompanies)
