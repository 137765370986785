import moment from "moment";

function getInsOutsPercentage(ins, outs){
    const pr =( outs*100/ins).toFixed(0);
    if(ins == 0 || outs == 0){
        return `0 %`
    }else if(pr == Infinity){
         return `0 %`
    }else{
         return `${100 -pr} %`
    } 
}
function getTotalValue(value){
    let total = 0;
    for(const property in value){
        total+=value[property]
    }

    return total;
}
export const getStEnrolled=(item) =>{
    let newData = [ { bold: true, data: ["Mon.", "Trials", "Tr. Ins", "Ins", "Outs", "I vs O"] } ]
    for(let i = 1; i <=12;i++){
        newData.push({
            bold:false,
            data:[moment(`2023-${i}-01`).format("MMM"), item?.trials[i], item?.trial_en[i], item?.general_en[i], item?.log_outs[i], getInsOutsPercentage(item?.general_en[i], item?.log_outs[i]) ]
        })
    }
    newData.push({
        bold:true,
        data:["Total", getTotalValue(item?.trials), getTotalValue(item?.trial_en), getTotalValue(item?.general_en), getTotalValue(item?.log_outs), getInsOutsPercentage(getTotalValue(item?.general_en), getTotalValue(item?.log_outs)) ]
    })
    return newData;
}

export const typeOfSt=(item)=>{
    let newData = [];
    if(item?.general_english && item?.general_english !== 0){
        newData.push({
            bold:false,
            data:["English", item?.general_english]
        })
    }
    if(item?.sat && item?.sat !== 0){
        newData.push({
            bold:false,
            data:["SAT I", item?.sat]
        })
    }
    if(item?.sat_math && item?.sat_math !== 0){
        newData.push({
            bold:false,
            data:["SAT Math", item?.sat_math]
        })
    }
    if(item?.sat_verbal && item?.sat_verbal !== 0){
        newData.push({
            bold:false,
            data:["SAT Verbal", item?.sat_verbal]
        })
    }
    if(item?.ielts && item?.ielts !== 0){
        newData.push({
            bold:false,
            data:["IELTS", item?.ielts]
        })
    }
    if(item?.toefl && item?.toefl !== 0){
        newData.push({
            bold:false,
            data:["TOEFL", item?.toefl]
        })
    }
    if(item?.gre_gmat && item?.gre_gmat !== 0){
        newData.push({
            bold:false,
            data:["GRE & GMAT", item?.gre_gmat]
        })
    }
    if(item?.duolingo && item?.duolingo !== 0){
        newData.push({
            bold:false,
            data:["Duolingo", item?.duolingo]
        })
    }
    if(item?.others_english && item?.others_english !== 0){
        newData.push({
            bold:false,
            data:["Other English", item?.others_english]
        })
    }
    if(item?.intensive_english && item?.intensive_english !== 0){
        newData.push({
            bold:false,
            data:["Int. English", item?.intensive_english]
        })
    }
    if(item?.intensive_sat && item?.intensive_sat !== 0){
        newData.push({
            bold:false,
            data:["Int. SAT", item?.intensive_sat]
        })
    }
    if(item?.intensive_ielts && item?.intensive_ielts !== 0){
        newData.push({
            bold:false,
            data:["Int. IELTS", item?.intensive_ielts]
        })
    }
    if(item?.intensive_toefl && item?.intensive_toefl !== 0){
        newData.push({
            bold:false,
            data:["Int. TOEFL", item?.intensive_toefl]
        })
    }
    if(item?.high_school && item?.high_school !== 0){
        newData.push({
            bold:false,
            data:["High S.", item?.high_school]
        })
    }
    if(item?.secondary_school && item?.secondary_school !== 0){
        newData.push({
            bold:false,
            data:["Secondary S.", item?.secondary_school]
        })
    }
    if(item?.elementary_school && item?.elementary_school !== 0){
        newData.push({
            bold:false,
            data:["Elementary S.", item?.elementary_school]
        })
    }
    if(item?.trial_class && item?.trial_class !== 0){
        newData.push({
            bold:false,
            data:["Trial class", item?.trial_class]
        })
    }
    if(item?.university && item?.university !== 0){
        newData.push({
            bold:false,
            data:["University", item?.university]
        })
    }
    if(item?.school_math && item?.school_math !== 0){
        newData.push({
            bold:false,
            data:["School Math", item?.school_math]
        })
    }
    if(item?.sat_subject && item?.sat_subject !== 0){
        newData.push({
            bold:false,
            data:["SAT Subject", item?.sat_subject]
        })
    }
    if(item?.intensive_gre_gmat && item?.intensive_gre_gmat !== 0){
        newData.push({
            bold:false,
            data:["Intensive GRE, GMAT", item?.intensive_gre_gmat]
        })
    }
    if(item?.general_kids && item?.general_kids !== 0){
        newData.push({
            bold:false,
            data:["General KIDS", item?.general_kids]
        })
    }
    if(item?.speaking_mode && item?.speaking_mode !== 0){
        newData.push({
            bold:false,
            data:["Hour Sp.Teacher Mode", item?.speaking_mode]
        })
    }
    if(item?.it_fundamental && item?.it_fundamental !== 0){
        newData.push({
            bold:false,
            data:["IT Fundamental", item?.it_fundamental]
        })
    }
    if(item?.es_lang_1_1 && item?.es_lang_1_1 !== 0){
        newData.push({
            bold:false,
            data:["ES Lang. 1-1", item?.es_lang_1_1]
        })
    }
    if(item?.es_lang_group && item?.es_lang_group !== 0){
        newData.push({
            bold:false,
            data:["ES Lang. Group", item?.es_lang_group]
        })
    }
    if(item?.es_maths_1_1 && item?.es_maths_1_1 !== 0){
        newData.push({
            bold:false,
            data:["ES Maths. 1-1", item?.es_maths_1_1]
        })
    }
    if(item?.es_maths_group && item?.es_maths_group !== 0){
        newData.push({
            bold:false,
            data:["ES Maths group", item?.es_maths_group]
        })
    }
    if(item?.es_pre_school_1_1 && item?.es_pre_school_1_1 !== 0){
        newData.push({
            bold:false,
            data:["ES Pre-school 1-1", item?.es_pre_school_1_1]
        })
    }
    if(item?.es_pre_school_group && item?.es_pre_school_group !== 0){
        newData.push({
            bold:false,
            data:["ES Pre-school Group", item?.es_pre_school_group]
        })
    }
    if(item?.es_school_prep && item?.es_school_prep !== 0){
        newData.push({
            bold:false,
            data:["ES School Prep 1-1", item?.es_school_prep]
        })
    }
    if(item?.html_css_basics && item?.html_css_basics !== 0){
        newData.push({
            bold:false,
            data:["HTML/CSS/BASICS", item?.html_css_basics]
        })
    }
    if(item?.pr_language && item?.pr_language !== 0){
        newData.push({
            bold:false,
            data:["PR Language", item?.pr_language]
        })
    }
    if(item?.frameworks && item?.frameworks !== 0){
        newData.push({
            bold:false,
            data:["Frameworks", item?.frameworks]
        })
    }
    return newData;
}
function getStatisticsValue(data){
    if(data > 100){
        return 100;
    }else if(data === 'NaN'){
        return 0;
    }else{
        return data
    }
}
export const examTakens = (examTakes, monthStatus)=>{
    let hwSAT = [];
    let hwGEN = [];
    if(monthStatus && monthStatus.monthly_homework_taken){
        for(let i =0; i<monthStatus.monthly_homework_taken.length; i++){
            if(monthStatus.monthly_homework_taken[i].split(' ').includes('SAT')){
                hwSAT.push(monthStatus.monthly_homework_taken[i])
            }else{
                hwGEN.push(monthStatus.monthly_homework_taken[i])
            }
        }
        
    }
    let newData = [];
    newData.push({
        bold:true,
        data: ["Mode", "St. Count", "Ex. Count", "%"]
    })
    newData.push({
        bold:false,
        data: ["G.Eng", examTakes?.general_english_student, examTakes?.general_english_exam, `${getStatisticsValue(((examTakes.general_english_exam*100)/(examTakes.general_english_student*4)).toFixed(0))}%`]
    })
    newData.push({
        bold:false,
        data: ["SAT I", examTakes?.sat_student, examTakes?.sat_exam_submit, `${getStatisticsValue(((examTakes.sat_exam_submit*100)/(examTakes.sat_student*4)).toFixed(0))}%`]
    })
    newData.push({
        bold:false,
        data: ["IELTS", examTakes?.ielts_student, examTakes?.ielts_exam_submit, `${getStatisticsValue(((examTakes.ielts_exam_submit*100)/(examTakes.ielts_student*8)).toFixed(0))}%`]
    })
    newData.push({
        bold:false,
        data: ["TOEFL", examTakes?.toefl_students, '0', '0%']
    })
    newData.push({
        bold:false,
        data: ["PT G.Eng", "", hwGEN.length, `${getStatisticsValue(((hwGEN.length*100)/(examTakes.general_english_student*15)).toFixed(0))}%`]
    })
    newData.push({
        bold:false,
        data: ["PT SAT", "", hwSAT.length, `${getStatisticsValue(((hwSAT.length*100)/(examTakes.sat_student*3)).toFixed(0))}%`]
    })
    return newData
}

export const getMonthyStatus = (item, officeExamTakens) => {
    let newData = [];
    newData.push({
        bold:false,
        data: ["Students", item?.studentCount]
    })
    newData.push({
        bold:false,
        data: ["Check-Ins", item?.attendance]
    })
    return newData
}

export const getStatRefFormat = (items, titles) => {
    let newData = [];
    items.forEach(item=>{
        newData.push({
            bold:false,
            data: [titles?.find(x=>x?.value == item?.where_hear_about)?.label, item?.total]
        }) 
    })
    return newData
}

export const getTotalExams=(data)=>{
    let newData = [];
    newData.push({bold:false, data: ["Total Exams Taken", data?.totalExamTaken]});
    newData.push({bold:false, data: ["Yesterday", data?.totalExamTakenYestarday]});
    newData.push({bold:false, data: ["Last Month Exams", data?.totalExamTakenLastMonth]});
    newData.push({bold:false, data: ["Last year Exams", data?.totalExamTakenLastYear]});
    return newData;
}
export const getTotalEssay=(data)=>{
    let newData = [];
    newData.push({bold:false, data: ["Total Essays", data?.totalEssays]});
    newData.push({bold:false, data: ["Yesterday", data?.totalEssaysYesterday]});
    newData.push({bold:false, data: ["Last Month Essays", data?.totalEssayLastMonth]});
    newData.push({bold:false, data: ["Last year Essays", data?.totalEssaysLastYear]});
    return newData;
}
export const getTotalSpeaking=(data)=>{
    let newData = [];
    newData.push({bold:false, data: ["Total Speakings status", data?.totalSpeaking]});
    newData.push({bold:false, data: ["Yesterday", data?.totalSpeakingYesterday]});
    newData.push({bold:false, data: ["Last Month Speakings", data?.totalSpeakingLastMonth]});
    newData.push({bold:false, data: ["Last year Speakings", data?.totalSpeakingLastYear]});
    return newData;
}
export const getTotalSpeakingPr=(data)=>{
    let newData = [];
    newData.push({bold:false, data: ["Total Speakings Pr. status", data?.totalSpeakingPr]});
    newData.push({bold:false, data: ["Yesterday", data?.totalSpeakingPrYesterday]});
    newData.push({bold:false, data: ["Last Month Speaking Pr.", data?.totalSpeakingPrLastMonth]});
    newData.push({bold:false, data: ["Last year Speaking Pr.", data?.totalSpeakingPrLastYear]});
    return newData;
}