
export const engLang = {
    navs:{
        dashboard: "Dashboard",
        gen_exams: "Exams",
        hw_exams: "Exam PT",
        sp_record: "Sp. Record",
        sp_practice_st: "Sp. Practice",
        essay_writing: "Essay Writing",
        ial_exams: "A Level",
        exam_rws: "Exam graded",
        hws_rws: "Exam PT graded",
        sp_rec_rws: "Sp.R graded",
        sp_prc_rws: "Sp.P graded",
        essay_rws: "Essays Graded",
        ial_ex_rws: "A Level graded",
        stud_audio: "Stud. Audio",
        check_ins: "Check-ins",
        courses: "Courses",
        certificate: "Certs",
        downloads: "Downloads",
        teacher_reviews: "Reports",
        exam_results: "Exam graded",
        hw_results: "Exam PT graded",
        students: "Students",
        parents: "Parents",
        exam_results: "Exam graded",
        hw_results: "Exam PT graded",
        es_reviews: "Essays graded",
        ial_reviews: "A level graded",
        sp_reviews: "Sp.R. graded",
        sp_practice: "Sp.P graded",
        student_stats: "Student Stats",
        teachers: "Teachers",
        t_counting: "T. Counting",
        teacher_stats: "Teacher Stats",
        reports: "Reports",
        rp_ungraded: "Rp. Ungraded",
        es_ungraded: "Es. Ungraded",
        sp_ungraded: "Sp. Ungraded",
        cs_check_ins: "Cs. Check-Ins",
        sp_check_ins: "Sp. Check-Ins",
        gen_stats: "Whole Stats",
        messages: "Messages",
        applicants: "Applicants",
        resources: "Resources",
        courses: "Courses",
        managers: "Managers",
        payment_due: "Payments",
        bonuses: "Bonuses",
        public_pages: "Public pages",
        all_students: "All Students",
        all_exam_results: "Exam graded",
        all_hw_results: "Exam PT graded",
        all_es_reviews: "Essays Graded",
        sp_teacher_checkin:'Sp. Checked-Ins',
        teacher_check:'Teacher check-in',
        onlineApply:"Applicants",

        st_part_1: "ACTIONS' MENU",
        st_part_2: "REVIEWS' MENU",
        st_part_3: "OTHERS' MENU",

        pr_part_1: "MENU",
        pr_part_2: "OTHER",

        ch_part_1: "STUDENTS' MENU",
        ch_part_2: "TEACHERS' MENU",
        ch_part_3: "REPORTS' MENU",
        ch_part_4: "CHECK-INS",
        ch_part_5: "OTHERS",

        ht_part_1: "TEACHERS' TOOLS",
        ht_part_2: "REVIEWS' MENU",
        ht_part_3: "REPORTS' MENU",
        ht_part_4: "OFFICE TOOLS",
        ht_part_5: "OTHERS",
        it_support:"IT Support",
        ex_excp:"Ruled outs",
        coach_excp:"Coach Extendeds",
        appointments:"Appointments",
        exams_graded: "Exams graded"
    },
    //esas cardin icherisindeki sozler (es review falan olan)
    cheating:"Cheating",
    no_stats_available: "No stats available",
    view: "View",
    replied:"Replied",
    waiting: "Waiting",

    // hw preview sevifesi, (meneger mellim hw results a baxanda)
    total:"Total",
    points:"Points",
    answer:"Student Answer",
    result: "Final Result",
    correct: "Correct",
    wrong: "Wrong",
    partially_correct: "Partially correct",

    view_all: "View All",
    save: "Save",
    close: "Close",
    submit: "Submit",
    save_close: "Save & Close",
    delete: "Delete",
    update: "Update",
    add: "Add",
    yes: "Yes",
    no: "No",

    all_answered: "Completed",
    unanswered: "Unanswered",
    part_answered: "Partially answered",

    teacher: "Teacher",  //message page
    class: "Class",  //message page

    please_select_group:"Please select a group to start messaging.",  //message page

    active: "Active", //message page
    contants: "Contacts",  //message page

    search: "Search",
    chat: "Chat",  //message page
    reply: "Reply",//message page
    send_msg_all: "Send a message for all",//message page

    essay: "Essay", //pop up
    word_count: "Word count",

    
    popupSaveTitle: "Saved Successfully",
    popupSubmitTitle: "Submitted Successfully",
    ess_save_popup: "Your changes have been successfully saved!",
    ess_submit_popup: "You will see it in your dashboard after reviewed!",
    last_5:"Should be at least 5 words to save the essay",
    record_warning: "Please record the audio before submiting!",
    last_50: "Should be at least 50 words to submit the essay",
    popup_went_wrong: "Something went wrong",
    teacher_ess_review_title: "Essay Reviewed!",
    teacher_ess_review_text: "Thanks. Please ask the student to check.",
    fill_grade_review:  "Please fill the grade and review fields",
    teacher_sp_review_title: "Speaking Reviewed!",
    teacher_sp_review_text: "Thanks. Please ask the student to check.",
    teacher_report_send:"Report sent successfully!",

    delete_popup_title: "Are you sure?",
    delete_popup_text: "You won't be able to revert this!",

    upload_img: "Upload an image",
    field_required: "This filed is required",
    fill_req_inputs: "Please fill the required fields",

    robo_question_waiting_text: "Please hold on while the Selty is creating a question for you",
    robo_outline_waiting_text: "Please hold on while the Selty is creating an outline for you.",
    robo_waiting: "Kindly hold on while the Selty assesses the essay.",
    speaking_robo_wrong_text: "Regrettably, I'm unable to evaluate the recording. Therefore, I request that the teacher assesses it manually. I apologize for being unable to assist you.  Best regards, Selty",
    final_grade: "Final grade",
    model_essay: "Model essay",

    sp_record: "Record",
    sp_recording: "Recording",

    student_answer: "Student Answer",
    score: "score",

    min_8_words: "Please create a password: Min 8 characters, with a letter and a number.",
    profile_upt: "Profile updated successfully!",
    upl_pr_img: "Upload profile Image",
    added:"Added",
    edited:"Edited",

    select_less_mode: "Select a lesson mode",
    select_course: 'Select a course',
    select_unit: "Select a unit",
    select_teacher: "Select a teacher",
    select_student: "Select a student",
    select_year: "School Grade",
    popup_checkin: "Check-Ins",
    hour: "Hour",
    lesson_mode: "Lesson Mode",
    date: "Date",

    //ex results popup
    name: "Name",
    part_title: "Part title",
    scored: "Scored",
    max_score: "Max. Score",

    payment_popup_text: "Are you sure to change check-in to paid status?",
    note: "Note",



    //sp_practice popup
    start_conv: "Talk to me",
    ask_react: "Ask to reply",
    please_wait: " Please wait",
    submit_grading: "Submit",


    //------------

    payy_mode: "Pay mode",
    sch_year:"School Year",
    st_unlocked_succ: "Student unlocked successfully",
    st_assign: "Student assigned successfully",
    group_edited: "Group edited successfully",


    //weekly statistics
    exam_type:"Exam Type",
    count: "Count",


    //-----------

    sun: "Sun", 
    mon: "Mon",
    tue: "Tue",
    wed: "Wed",
    thu: "Thu",
    fri: "Fri",
    sat: "Sat",


    load_more: 'Load More',
    show_less: "Show less",

   

    last_month: "Last Month",
    last_week: "Last Week",
    yesterday: "Yesterday",


    create_pearson_cert: "Create a Pearson Cert",
    create_unit_cert: "Create a Unit Cert",


    not_check_yet: "not checked yet",
    nothing_found: "nothing found",

    status_close: "Close", //essay sp close olmasi
    status_closed: "Closed", //essay sp close olmasi
    
    pass: "Pass",
    fail: "Fail",

    //company head student profile logs
    assign_course: "Assign a Course",
    remove_course: "Removed Course",
    assign_teacher: "Assign a Teacher",
    remove_teacher: "Remove a Teacher",

    //---------

    unlocked: "Unlocked",
    lock: "Lock",
    unlock: "Unlock",
    locked: "Locked",
    essay_unlck_succ: "Essay unlocked successfully.",
    essay_lck_succ: "Essay locked successfully.",
    sp_unlck_succ: "Speaking unlocked successfully.",
    sp_lck_succ: "Speaking locked successfully.",
    exam_unlck_succ: "Exam unlocked successfully.",
    hw_unlck_succ: "PT unlocked successfully.",
    open: "Open",
    done: "Done",


    teacher_enroll: "Teacher Enroll",
    course_enroll: "Course Enroll",
    completed: "Completed",
    add_bonus: "Add a bonus",
    add_teacher: "Add a teacher",
    add_office_manager: "Add a Manager",
    all:"All",
    failed: "Failed",
    passed: "Passed",


    st_enrolled: "Students enrolled",
    st_mode: "Student modes",
    ex_taken: "Exams Taken",
    monthly_status: "Monthly status",
    total_time: "Total time", //checkin card

    manager_deleted_succ: "Manager deleted successfully!",


    //sp checkin
    today: "Today",
    last_7_dat: "Last 7 days",


    //t counting
    sub_total: "Sub-total",
    bonus: "Bonus",
    total_this_month: "Total for this month",
    summary: "Summary",

    submitted: "Submitted",
    write_essay: "Write an essay",
    not_applicable: "Not Applicable",
    record_now: "Record Now",
    speak_now: "Speak now",
    already_written: "Essay already written.",


    //st dashboard
    hw_todo: "PT TODO",
    ess_rev: "Essay Graded",
    sp_reviews: "Speaking Graded",


    //teacher all essay
    mids_ends: "MIDs & ENDs",
    unit: "Unit",

    //
    name: "Name",
    email: "Email",
    category: "Position",
    phone_number: "Phone Number",
    date_of_birth: "Date of Birth",
    first_name: "First Name",
    last_name: "Last Name",
    password: "Password",
    select_a_role: "Select a role",
    select_student: "Select a student",


    // /////////son
    start: "Start",
    sp_submitted: "Speaking Submitted!",
    grade: "Grade",
    checkIn_submit: "Check-In submitted",
    checkin_success: "Checked in successfully",
    already_checkin:  "You have already checked in for today.",
    exam_reminder: "Exam unlock checker",
    robo_speaking: "Kindly hold on while the Selty assesses the speaking.",
    others: "Others",
    standart: "Standart",
    intensive: "Intensive",
    make_pay: "Make a pay",

    noexam_unlock: "No exams unlocked",
    please_ask_manager_exam: "(Please ask your manager to unlock the exam for you)",
    no_data_found_empty: "No data found",
    go_to_reports: 'Go to reports',
    edit_profile: "Edit Profile",
    not_mentioned: "Not mentioned",
    send: "Send",


    student_navs: {
        gen_english: "Exam",
        gen_english_hw: "Exam PT",
        essays: "Essays",
        speakings: "Speakings",
        a_level: "A Level",
        gen_english_res: "English",
        gen_english_hw_res: "English PT",
        all_ielts: "All IELTS",
        sat_math: "SAT math",
        sat_math_hw: "SAT math PT",
        sat_verbal: "SAT verbal",
        sat_verbal_hw: "SAT verbal PT",
        all_others: "All others",
        essays: "Essays",
        a_level: "A Level",
        sp_record: "Sp. Record",
        sp_practice: "Sp. Practice",
        payments: "Payments",
        certs: "Certs",
        reports: "Reports",
        other_reports: "Other Reports",
        logs: "Logs",
        unlock_hws: "Unlock PTs",
        part1: "Unlocks", 
        part2: "Results", 
        part3:  "Reviews",
        mock_exam:"Mocks",
        home:"Home",
        all_others_pt:"All others PT",
        notes:"Notes",
        end_exam_sch:"End of C Exams"
    },





    add_a_student: "Add a Student",
    send_email_student: "Send emails to the student as well",
    student_per_info: "Student Data",
    create_student: "Create a Student",
    edit_student: "Edit Student",
    dictionary: "Dictionary",
    createGroup: "Create a Group",
    report_reviews: "Reports",
    enrol_course: "Enroll a Course",
    edit: "Edit",
    add_review: "Add a Report",
    add_parent: "Add a parent",
    edit_parent: "Edit parent",
    full_name: "Full Name",
    students: "Students", //parent popup,
    subject:"Subject",
    title: "Title",
    senior_teacher: "Senior teacher status",

    // roles
    role_teacher: "Teacher",
    role_student: "Student",
    role_parent: "Parent",
    role_head_teacher: "Head teacher",
    role_content_manager: "Content manager",
    role_office_manager: "Manager",
    role_speaking_teacher: "Speaking teacher",
    role_company_head: "General Manager",


    // new new new
    ess_to_review:"Essays To Review",
    sp_to_review:"Speakings To Review",
    mid_end_to_review:"MIDs & ENDs Review",
    dailyContrl: "Daily Control",



    //new new new last
    upload: "Upload",
    appeal_pending:"Your appeal is pending",
    // appeal_exception: "Apply for an exception",
    appeal_exception: "Rule Exception",
    applead_accepted: "Your appeal was accepted",
    applead_rejected: "Your appeal was rejected",
    upload_id_card: "Upload your ID card Image",

    waiting_unlock: "Needs to be unlocked",
    before_20_minute:"You should aim to spend at least 20 minutes completing the exam before submitting it.",
    send_approve:"Approve and Send",

    // coach_exception:'Apply for a coach exception',
    coach_exception:'Coach Extension',
    coach_app_pending: 'Your coach appeal is pending',
    coach_app_accept:'Your coach appeal was accepted',
    coach_app_reject:"Your coach appeal was rejected",
    upload_coach_excp_docs:'Upload your extension confirmation document',

    review:"Review",
    download:"Download",
    rp_all:"All",
    rp_waiting:"Waiting",
    approved_by:"Approved by:",
    rp_approve:"Approve",

    coach_tracker:'Quality Health Tracker (Monthly)',
    coach_mustbe_done:'Tasks Must Be Done',
    coach_need_to_be_done:'Tasks Need to Be Done',
    coach_already_done:'Tasks Already Done',
    coach_outstanding:'No outstanding tasks; your students are reaching new heights',
    coach_refresh_btn:'Refresh it',
    current_exam_gpa: 'Current Exam GPA',
    new:'New',
    expections:'Exceptions',
    contracts:'Contract',
    uploaded:'Uploaded',
    teacher_contract_title:"Signatures",
    teacher_contract_submut: 'Signatures have been submitted, but you can still add more.',


    already_passed:"Already passed",
    not_taken_yet:"Not taken yet",
    have_failed:"Have failed",

    upl_enr:"Upload & Enroll",
    pay_upl:"Pay & Upload",
    upl_save:"Upload & Save",
    num_lessons:"New lesson count",
    pay_note:"Payment note",
    go_pay:"Please, go to Payments menu to pay and unlock the student",
    pay_unlck:"Pay to unlock",
    add_pay:"Add a pay",
    lesson_payment:"Lesson payment",
    add_note:"Add a note",
    references_stats:"Reference Stats",



    pay_receipts_accountant:"Pay Receipts",
    contracts_accountant:"Contracts",
    pass_req_title:"Password must be",
    pass_req_line_1: "at least 8 characters",
    pass_req_line_2: "at least one letter or a number",
    pass_req_line_3: "CELT is forbidden to use",

    salary_docs: "Salary docs",
    pays_acc:"Pays",
    t_count_acc:"T.counting",
    celtgo_acc:"S. Abroad",
    fee:"Fee",

    senior_t:"Senior teacher",
    amount:"Amount",
    alert_edit_fee:"Please, go to payments menu to update the fee.",

    s_abroad:"S. Abroad",
    student_finance:"Student Finance",
    edu_expences:"XTM Finance",
    total_ins:"Total Incomes",
    total_outs_student:"Expenses Student",
    total_outs_worker:"Expenses XTM",
    total_net:"Total Remaining",
    acc_student:"Students",
    educon:"XTM",
    ins:"Incomes",
    outss:"Expenses",
    acc_continue:"Contracts",
    acc_actions:"Actions",
    weekly_healthy_tracker:"Quality Health Tracker (Weekly)",
    profile_email_error:"The email address you entered is already in use with another account. Please try using a different email address. If you believe this is a mistake, feel free to reach out to our support team for further assistance.",
    msgReviewing:"Reviewing",
    msgSolved:"Solved",

    type_online:"Online",
    type_oncampus:"Oncampus",
    add_ex_sch:"Book an EoC exam",
    sch_this_ex:"Select this exam",
    ex_not_ex:"Not available",
    available:"Available",


    apply_written:"Enrolled",
    apply_called:"Called",
    apply_not_answer:"Did not answer",
    apply_wrong_number:"Wrong number",


    new_req_text1:"There are students who want to register with your center.",
    new_req_text2:"The customer trusts the first caller)",
    new_req_btn:"Check it out",
    remaining_count:"Remaining",


    failed_groupStudents:"Failed group students",

    valid_exam_pass:"Please complete all the unit exams before taking the EoC exam.",
    valid_phone_num:"Please ensure the student's mobile number is correct before booking the EoC exam.",
    read_reports:"Read reports",
    unread_reports:"Unread reports"
}
export const azLang = {
    navs:{
        dashboard: "Ana Səhifə",
        gen_exams: "İmtahanlar",
        hw_exams: "İmtahanlar PT",
        sp_record: "Dan. Rekordla",
        sp_practice_st: "Selty Müəllim",
        essay_writing: "Esse yaz",
        ial_exams: "A Level",
        exam_rws: "İmtahanlar  ",
        hws_rws: "İmtahanlar PT ",
        sp_rec_rws: "Dan. Rekordlar",
        sp_prc_rws: "Selty Rəyləri",
        essay_rws: "Esse rəyləri",
        ial_ex_rws: "A Level ",
        stud_audio: "Kitab Audio",
        check_ins: "İştiraklar",
        courses: "Fənnlər",
        certificate: "Sert-lar",
        downloads: "Resurslar",
        teacher_reviews: "Müəllim Rəyləri",
        exam_results: "İmtahanlar",
        hw_results: "İmtahanlar PT",
        students: "Tələbələr",
        parents: "Valideynlər",
        exam_results: "İmtahanlar",
        hw_results: "İmtahanlar PT",
        es_reviews: "Esse rəyləri",
        ial_reviews: "A Level rəyləri",
        sp_reviews: "Dan. Rekordlar",
        sp_practice: "Selty rəyləri",
        student_stats: "Tələbə stat.",
        teachers: "Müəllimlər",
        t_counting: "Müəllim Hesablar",
        teacher_stats: "Müəllim Stats",
        reports: "Müəllim Rəyləri",
        rp_ungraded: "Müəllim Rəyləri",
        es_ungraded: "Esse Rəyləri",
        sp_ungraded: "Dan. Rekordlar",
        cs_check_ins: "Dərs. İştirak",
        sp_check_ins: "Danışıq İştirak",
        gen_stats: "Statistikalar",
        messages: "Mesajlaşma",
        applicants: "Müraciətlər",
        resources: "Resurslar",
        courses: "Fənnlər",
        managers: "Menecerlər",
        payment_due: "Ödənişlər",
        bonuses: "Bonuslar",
        public_pages: "Mərkəz səhifələr",
        all_students: "Bütün tələbələr",
        all_exam_results: "İmtahan",
        all_hw_results: "İmtahan PT",
        all_es_reviews: "Esse rəyləri",
        sp_teacher_checkin:'Sp. Müəl. iştirak',
        teacher_check:'Müəllim Jurnalı',
        onlineApply:"Müraciətlər",

        st_part_1: "Əsas MENU",
        st_part_2: "Rəylər MENU",
        st_part_3: "Digər MENU",

        pr_part_1: "MENU",
        pr_part_2: "Digər",

        ch_part_1: "Tələbələr MENU",
        ch_part_2: "Müəllimlər MENU",
        ch_part_3: "Rəylər MENU",
        ch_part_4: "İştiraklar",
        ch_part_5: "Digər",

        ht_part_1: "Müəllimlər MENU",
        ht_part_2: "Rəylər MENU",
        ht_part_3: "Rəylər MENU",
        ht_part_4: "Mərkəz MENU",
        ht_part_5: "Digər",
        it_support:"Texniki dəsdək",
        ex_excp:"Azad olanlar",
        coach_excp:"Vaxtı uzadılanlar",
        appointments:"Randevular",

        exams_graded: "İmtahan nəticəsi"
        
    },
    //esas cardin icherisindeki sozler (es review falan olan)
    cheating:"Aldatma",
    no_stats_available: "Heç bir data yoxdur",
    view: "Baxın",
    replied:"Cavablandı",
    waiting: "Gözləyir",

    // hw preview sevifesi, (meneger mellim hw results a baxanda)
    total:"Cəmi",
    points:"Qiymət",
    answer:"Tələbənin cavabı",
    result: "Son nəticə",
    correct: "Doğru",
    wrong: "Yalnış",
    partially_correct: "Yarı doğru cavab",

    view_all: "Hamısına bax",
    save: "Yaddaşa ver",
    close: "Bağla",
    submit: "Göndər",
    save_close: "Yaddaş ver və bağla",
    delete: "Ləvğ et",
    update: "Yenilə",
    add: "Əlavə ET",
    yes: "Bəli",
    no: "Xeyir",

    all_answered: "hamısı cavablandı",
    unanswered: "Cavablanmamış",
    part_answered: "yarım cavablanmış",

    teacher: "Müəllim",  //message page
    class: "Sinif",  //message page

    please_select_group:"Zəhmət olmasa qrup seçin.",  //message page

    active: "Aktiv", //message page
    contants: "Əlaqələr",  //message page

    search: "Axtar",
    chat: "Söhbət",  //message page
    reply: "Cavabla",//message page
    send_msg_all: "Hər kəsə mesaj göndər",//message page

    essay: "Esse", //pop up
    word_count: "Söz sayı",

    
    popupSaveTitle: "Uğurla yaddaşa verildi",
    popupSubmitTitle: "Uğurla göndərildi",
    ess_save_popup: "Dəyişikliklər yaddaşa verildi!",
    ess_submit_popup: "Müəllimlər qiymətləndirdikdən sonra nəticənizi görəcəksiniz!",
    last_5:"Yaddaşa vermək üçün ən az 5 söz yazmalısınız",
    record_warning: "Zəhmət olmasa səsinizi göndərmədən öncə rekord edin!",
    last_50: "Göndərmədən öncə ən az 50 söz yazmalısınız",
    popup_went_wrong: "Ups. Nəsə yalnış oldu. Təkrar sınayın",
    teacher_ess_review_title: "Esseniz qiymətləndirildi!",
    teacher_ess_review_text: "Təşəkkürlər. Zəhmət olmasa tələbədən baxmasını xahiş edin.",
    fill_grade_review:  "Zəhmət olmasa qiymət və rəy sahələrini doldurun",
    teacher_sp_review_title: "Səs yazınız qiymətləndirildi!",
    teacher_sp_review_text: "Təşəkkürlər. Zəhmət olmasa tələbədən baxmasını xahiş edin.",
    teacher_report_send:"Rəy uğurla göndərildi!",

    delete_popup_title: "Əminsiniz?",
    delete_popup_text: "Bunu geri qaytara bilməyəcəksiniz!",

    upload_img: "Şəkil yükləyin",
    field_required: "Bu xananın doldurulması mütləqdir",
    fill_req_inputs: "Zəhmət olmasa, tələb olunan sahələri doldurun",

    robo_question_waiting_text: "Selty sizin üçün sual hazırlayır. Zəhmət olmasa gözləyin",
    robo_outline_waiting_text: "Selty sizin üçün esse çərçivəsi hazırlayır. Zəhmət olmasa gözləyin",
    robo_waiting: "Selty sizin essenizi oxuyub qiymətləndirir. Zəhmət olmasa gözləyin.",
    speaking_robo_wrong_text: "Təəssüf ki, Səs yazısını qiymətləndirə bilmirəm. Ona görə də sizdən bunu qiymətləndirmənizi xahiş edirəm. Sizə kömək edə bilmədiyim üçün üzr istəyirəm. Hörmətlə, Selty",
    final_grade: "Son qiymət",
    model_essay: "Nümunə esse",

    sp_record: "Səs yaz",
    sp_recording: "Səs yazma",

    student_answer: "Tələbənin cavabı",
    score: "bal",

    min_8_words: "Zəhmət olmasa, şifrə yaradın: Minimum 8 simvol, bir hərf və bir rəqəm ilə.",
    profile_upt: "Profil uğurla yeniləndi!",
    upl_pr_img: "Profil şəklini yükləyin",
    added:"Əlavə edilib",
    edited:"Dəyişdirilib",

    select_less_mode: "Dərs formatı seçin",
    select_course: 'Fənn seçin',
    select_unit: "Bölüm seçin",
    select_teacher: "Müəllim seçin",
    select_student: "Tələbə seçin",
    select_year: "Məktəb təhsili",
    popup_checkin: "İştirak",
    hour: "Saat",
    lesson_mode: "Dərs formatı",
    date: "Tarix",

    //ex results popup
    name: "İmtahan",
    scored: "Balınız",
    max_score: "Maks. bal",
    part_title: "Bölüm adı",
    payment_popup_text: "İştirakı ödənilmiş statusuna dəyişməyə əminsiniz?",
    note: "Qeyd",



    //sp_practice popup
    start_conv: "Danışmağa başla",
    ask_react: "Buyur, Selty",
    please_wait: "Zəhmət olmasa gözləyin",
    submit_grading: "Yoxlamaya göndərin",


    //------------

    payy_mode: "Ödəniş növü",
    sch_year:"Məktəb ili",
    st_unlocked_succ: "Tələbə uğurla kiliddən çıxarıldı",
    st_assign: "Tələbə uğurla təyin edildi",
    group_edited: "Qrup uğurla dəyişdirildi",


    //weekly statistics
    exam_type:"İmtahan növü",
    count: "Sayı",


    //-----------

    sun: "Bazar", 
    mon: "B.Ertəsi",
    tue: "Cümə",
    wed: "C.Axşamı",
    thu: "Çərşəmbə",
    fri: "Ç.Axşamı",
    sat: "Şənbə",


    load_more: 'Daha çox göstər',
    show_less: "Daha az göstər",

   

    last_month: "Keçən ay",
    last_week: "Keçən həftə",
    yesterday: "Dünən",


    create_pearson_cert: "Pearson Cert",
    create_unit_cert: "Unit Cert",


    not_check_yet: "Hələ yoxlanılmayıb",
    nothing_found: "Vallah, heçnə tapılmadı",

    status_close: "Bağla", //essay sp close olmasi
    status_closed: "Bağlıdır", //essay sp close olmasi

    pass: "Keçid",
    fail: "Kəsir",

    //company head student profile logs
    assign_course: "Fənn təyin edin",
    remove_course: "Silinmiş fənn",
    assign_teacher: "Müəllim təyin edin",
    remove_teacher: "Müəllimi çıxarın",

    //---------

    unlocked: "Açıq",
    lock: "Bağla",
    unlock: "Açın",
    locked: "Bağlı",
    essay_unlck_succ: "Esse uğurla açıldı.",
    essay_lck_succ: "Esse uğurla bağlandı.",
    sp_unlck_succ: "Danışıq uğurla açıldı.",
    sp_lck_succ: "Danışıq uğurla bağlandı.",
    exam_unlck_succ: "İmtahan uğurla açıldı.",
    hw_unlck_succ: "PT imtahanı uğurla açıldı.",
    open: "Açıq",
    done: "Hazır",


    teacher_enroll: "Müəllim təyin et",
    course_enroll: "Fənn təyin et",
    completed: "Tamamlandı",
    add_bonus: "Bonus əlavə et",
    add_teacher: "Müəllim əlavə et",
    add_office_manager: "Menecer əlavə et",
    all:"Hamısı",
    failed: "Kəsilənlər",
    passed: "Keçənlər",


    st_enrolled: "Yazılan tələbələr",
    st_mode: "Təhsil formatı",
    ex_taken: "Götürülən imtahanlar",
    monthly_status: "Aylıq statistika",
    total_time: "Toplam saatlar", //checkin card

    manager_deleted_succ: "Menecer uğurla silindi!!",


    //sp checkin
    today: "Bugün",
    last_7_dat: "Son 7 gün",


    //t counting
    sub_total: "Yarı-toplam",
    bonus: "Bonus",
    total_this_month: "Bu ay toplam",
    summary: "Toplam hesab",

    submitted: "Göndərildi",
    write_essay: "Esse yaz",
    not_applicable: "Uyğun deyil",
    record_now: "Rekord Et",
    speak_now: "Danış",
    already_written: "Bu esse artıq yazılıb.",


    //st dashboard
    hw_todo: "İng. PT-lar",
    ess_rev: "Esse rəyləri",
    sp_reviews: "Dan. Rekordlar",


    //teacher all essay
    mids_ends: "MIDs & ENDs",
    unit: "Hissə",

    //
    name: "Ad",
    email: "Email",
    category: "Vəzifə",
    phone_number: "Mobil nömrə",
    date_of_birth: "Doğum tarixi",
    first_name: "Adınız",
    last_name: "Soyadınız",
    password: "Şifrə",
    select_a_role: "Rol seçin",
    select_student: "Tələbə seçin",


    // /////////son
    start: "Başla",
    sp_submitted: "Danışıq göndərildi!",
    grade: "Qiymət",
    checkIn_submit: "İştirak tamamlandı",
    checkin_success: "İştirak uğurla tamamlandı",
    already_checkin:  "Artıq iştirakı tamamlamısınız.",
    exam_reminder: "Həftəlik imtahan yoxla",
    robo_speaking: "Selty danışığı yoxlayır.Zəhmət olamsa gözləyin.",
    others: "Digər",
    standart: "Normal",
    intensive: "Sürətli",
    make_pay: "Ödəniş et",

    noexam_unlock: "Heç bir imtahan açılmayıb",
    please_ask_manager_exam: "(İmtahan açmaq üçün menecerə yaxınlaşın)",
    no_data_found_empty: "Heç bir məlumat tapılmadı",
    go_to_reports: 'Rəylərə ged',
    edit_profile: "Profili dəyiş",
    not_mentioned: "Deyilməyib",
    send: "Göndər",


    student_navs: {
        gen_english: "İmtahanlar",
        gen_english_hw: "İmtahanlar PT",
        essays: "Esselər",
        speakings: "Danışqlar",
        a_level: "A Level",
        gen_english_res: "İngilis dili",
        gen_english_hw_res: "İng. dili PT",
        all_ielts: "Bütün IELTS",
        sat_math: "SAT math",
        sat_math_hw: "SAT math PT",
        sat_verbal: "SAT verbal",
        sat_verbal_hw: "SAT verbal PT",
        all_others: "Digərləri",
        essays: "Esselər",
        a_level: "A Level",
        sp_record: "Dan. Rekordlar",
        sp_practice: "Selty müəllim",
        payments: "Ödəmələr",
        certs: "Sertifikatlar",
        reports: "Rəylər",
        other_reports: "Digər Rəylər",
        logs: "Logs",
        unlock_hws: "HW imtahanı aç",
        part1: "İmtahan Aç", 
        part2: "Nəticələr", 
        part3:  "Rəylər",
        mock_exam:"Sınaqlar",
        home:"Ana Səhifə",
        all_others_pt:"Digər PT-lər",
        notes:"Qeydlər",
        end_exam_sch:"Final imtahanlar"
        
    },




  

        add_a_student: "Tələbə əlavə et",
        send_email_student: "Tələbələrə də emaillər getsin",
        student_per_info: "Şəxsi məlumat",
        create_student: "Tələbə əlavə et",
        edit_student: "Tələbəni yenilə",
        dictionary: "Söz lüğəti",
        createGroup: "Qrup yarat",
        report_reviews: "Rəylər",
        enrol_course: "Fənn qeydiyyat et",
        edit: "Dəyiş",
        add_review: "Rəy əlavə et",
        add_parent: "Valideyn əlavə et",
        edit_parent: "Valideyni yenilə",
        full_name: "Tam ad",
        students: "Tələbələr", //parent popup,
        subject:"Fənn",
        title: "Başlıq",
        senior_teacher: "Təcrübəli müəllim statusu",
    
        // roles
        role_teacher: "Müəllim",
        role_student: "Tələbə",
        role_parent: "Valideyn",
        role_head_teacher: "Baş müəllim",
        role_content_manager: "Məzmun rəhbəri",
        role_office_manager: "Menecer",
        role_speaking_teacher: "Danışıq müəllimi",
        role_company_head: "Rəhbər",
      

        // new new new
        ess_to_review:"Yoxlamalı Esselər",
        sp_to_review:"Yoxlamalı Danışıqlar",
        mid_end_to_review:"MIDs & ENDs",
        dailyContrl: "Günlük iştiraklar",

        upload: "Yüklə",
        appeal_pending:"Müraciətiniz yoxlamadadır",
        appeal_exception: "İstisna üçün müraciət",
        applead_accepted: "Müraciətiniz qəbul edildi",
        applead_rejected: "Müraciətiniz rədd edildi",
        upload_id_card: "Ş. Vəsiqənizin şəklini yükləyin",

        waiting_unlock: "Esse bağlıdır",
        before_20_minute: "İmtahanı 20 dəqiqədən tez etmək məsləhət görülmür. Zəhmət olmasa daha diqqətlə imtahana baxın.",
        send_approve:"Təsdiq et və Göndər",

        coach_exception:'Tələbənin yavaş davamı üçün müraciət',
        coach_app_pending: 'Davamiyyət müraciətiniz gözləmədədir',
        coach_app_accept:'Davamiyyət müraciətiniz qəbul edildi',
        coach_app_reject:"Davamiyyət müraciətiniz rədd edildi",
        upload_coach_excp_docs:'Davamiyyət müraciətiniz üçün təsdiq sənədi yükləyin.',
        review:"Rəy",
        download:"Yüklə",
        rp_all:"Bütün Rəylər",
        rp_waiting:"Gözləyənlər",
        approved_by:"Təsdiq etdi:",
        rp_approve:"Təsdiq et",

        coach_tracker:'Aylıq Keyfiyyət Göstəricisi',
        coach_mustbe_done:'Geri qalan tələbələr',
        coach_need_to_be_done:'Bu həftə Tamamlayacaqlar',
        coach_already_done:'Bu həftə tamamalamışlar',
        coach_outstanding:'Tələbəriniz bütün tapşırıqları tamamlayıb. təşəkkürlər',
        coach_refresh_btn:'Yenilə',
        current_exam_gpa: 'İmtahan ortalaması (GPA)',
        new:'Yeni',
        expections:'İstisnalar',
        contracts:'Müqavilə',
        uploaded:'Yükləndi',
        teacher_contract_title:"İmzalar",
        teacher_contract_submut: 'İmza sənədləriniz göndərildi. Lazım olsarsa əlavə edə bilərsiniz.',
        already_passed:"Keçib",
        not_taken_yet:"Götürməyib",
        have_failed:"Kəsilib",


        upl_enr:"Yüklə və Tamamla",
        pay_upl:"Ödə və Yüklə",
        upl_save:"Yüklə və Tamamla",
        num_lessons:"Ödənilmiş dərs sayı",
        pay_note:"Ödəmə üçün qeyd",
        go_pay:"Zəhmət olmasa Payments (Ödənişlər) menüsünə gedib, kilidi açın.",
        pay_unlck:"Kilidi açmaq üçün ödə",
        add_pay:"Ödəmə əlavə et",
        lesson_payment:"Dərs ödənişi",
        add_note:"Qeyd əlavə et",
        references_stats:"Referans statistikası",

        pay_receipts_accountant:"Ödəniş qəbzləri",
        contracts_accountant:"Müqavilələr",
        pass_req_title:"Şifrə:",
        pass_req_line_1: "Ən azı 8 simvol olmalıdır",
        pass_req_line_2: "Ən azı bir hərf və ya rəqəm içərməlidir",
        pass_req_line_3: "'CELT' sözü istifadəsi qadağandır",

        salary_docs: "Maaş PDF-lər",
        pays_acc:"Ödənişlər",
        t_count_acc:"Maaşlar",
        fee:"Ödəniş",
        senior_t:"Təcrübəli müəllim",
        amount:"Miqdar",
        celtgo_acc:"Xaricdə Təhsil",
        alert_edit_fee:"Ödənişi dəyişmək üçün Payments menyusundan istifadə edin",


        s_abroad:"Xaricdə Təhsil",
        student_finance:"Tələbələr",
        edu_expences:"XTM Xərcləri",
        total_ins:"Toplam gəlir",
        total_outs_student:"Xərclər (tələbə)",
        total_outs_worker:"Xərclər (XTM)",
        total_net:"Xalis qalan",
        acc_student:"Tələbələr",
        educon:"XTM",
        ins:"Gəlirlər",
        outss:"Xərclər",
        acc_continue:"Müqavilələr",
        acc_actions:"Ətraflı",

        weekly_healthy_tracker:"Həftəlik Keyfiyyət Göstəricisi",
        profile_email_error:"Daxil etdiyiniz e-poçt ünvanı artıq başqa bir hesabla istifadə olunur. Lütfən, başqa bir e-poçt ünvanı sınayın. Əgər bu bir səhv olduğunu düşünürsünüzsə, daha ətraflı yardım üçün dəstək komandamıza müraciət edə bilərsiniz.",
        msgReviewing:"Yoxlanılır",
        msgSolved:"Həll olundu",

        type_online:"Distant",
        type_oncampus:"Əyani",
        add_ex_sch:"İmtahana randevu al",
        ex_not_ex:"Uyğun deyil",
        available:"Uyğun",

        apply_written:"Yazıldı",
        apply_called:"Zəng olundu",
        apply_not_answer:"Cavab vermədi ",
        apply_wrong_number:"Nömrə səhv idi",

        new_req_text1:"Sizin mərkəzə yazılmaq istəyənlər var.",
        new_req_text2:"Müştəri ilk zəng edənə inanır)",
        new_req_btn:"Baxın",
        remaining_count:"Qalan dərs",
        failed_groupStudents:"Qrupda kəsilən tələbələr",

        valid_exam_pass:"Zəhmət olmasa, EoC imtahanından əvvəl bütün unit imtahanlarını tamamlayın.",
        valid_phone_num:"Zəhmət olmasa, randevu götürməzdən əvvəl tələbənin mobil nömrəsinin düzgün olduğuna əmin olun.",
        

        read_reports:"Oxunmuş reportlar",
        unread_reports:"Oxunmamış reportlar"

}

