import React, { Component } from 'react'
import { changeStateValue, resetStateValue } from '../../../../redux/MainReducer';
import { connect } from 'react-redux';
import Input from '../../../../components/modals/components/Input';
import TextArea from '../../../../components/TextArea';
import FileInput from '../../../../components/FileInput';
import { insertNewData } from '../../../../actions/MainAction';
import { getTeacherSalary } from '../../../../actions/StaffAction';
import { bigAlert, mixinAlert } from '../../../../actions/SweetAlerts';
import moment from 'moment';
import Header from '../../../../components/modals/Header';
import PaymentReceipt from './PaymentReceipt';

export class PaymentParams extends Component {
    state={
        fileType:'image',
        btnDisabled:false
    }
    changePage=(page)=>{
        this.props.changeStateValue({
            name:"studentCalculatorModal.page",
            value:page
        })
    }
    onCloseData=()=>{
        this.props.resetStateValue({name:"studentCalculatorModal"})
    }
    saveEnrollBtn(data, lessonModes){
       
        if(data?.count !="" && data?.course_id !="" && data?.fee !="" && 
        data?.lesson_hour !="" && data?.lesson_mode_id !="" && data?.student_group_id !="" &&
        data?.study_mode !="" && data?.teacher_id !="" && (data?.file !="" || data?.image !="")){
            // console.log(data)
            const date = new Date()
            let formData = new FormData();
            formData.append('study_mode', data.study_mode);
            formData.append('teacher_id', data.teacher_id);
            formData.append('lesson_mode', lessonModes?.find(item=>item?.id === data.lesson_mode_id)?.title);
            formData.append('lesson_houre', data.lesson_hour);
            formData.append('student_group_id', data.student_group_id);
            formData.append('student_id', this.props.student?.id);
            formData.append('lesson_mode_id', data.lesson_mode_id);
            formData.append('course_id', data.course_id);
            formData.append('date', moment(date).format("YYYY-MM"));
            formData.append('check_in', JSON.stringify([]));
            formData.append('fee', data?.fee);
            formData.append('count', data?.selected_count);
            formData.append('note', data?.note);
            formData.append('payment_id', this.props.oldestEnrollCount?.id);
           
            if(data?.image){
                formData.append('image', data?.image);
            }
            if(data?.file){
                formData.append('file', data?.file);
            }
            this.setState({btnDisabled:true});
            let url = 'teacherEnrolls';
            this.props.insertNewData(url, formData)
            .then(resp=>{
                this.setState({btnDisabled:false})
                if(resp === "success"){
                   
                    bigAlert("Done!", this.props.lang?.st_assign, "success");
                    this.props.getTeacherSalary(data?.teacher_id, moment(date).format("YYYY-MM"));
                    this.props.onClose();
                    this.onCloseData()
                }else{
                    bigAlert("Error!", this.props.lang?.popup_went_wrong, "error")
                }
            })
        }else{
            mixinAlert("warning", "Please, Upload a payment receipt")
        }
    }
    changeFileType=(type)=>{
        this.setState({
            fileType:""
        });
        this.props.changeStateValue({ name:"teacherEnrollModalData.image", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.image_full_url", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.file", value:null })
        this.props.changeStateValue({ name:"teacherEnrollModalData.file_full_url", value:null })
        setTimeout(()=>{
            this.setState({
                fileType:type
            });
        },100)
      }
  render() {
    const {studentCalculatorModal, lang, lessonModes} = this.props;
    const {fileType, btnDisabled} = this.state;
    return (
        <div className='bg-white w-[700px] shadow rounded-[10px] max-[730px]:ml-[20px] max-[730px]:mr-[20px]'>
            {/* <h2 className='text-center txt-blue font-semibold text-[24px]'>Payment confirmation</h2> */}
            <Header title="Payment confirmation" closeBtn={()=>this.props.onClose()} />
            <div className=' p-[20px]'>
                <div className='grid grid-cols-2 gap-[20px] max-[730px]:grid-cols-1'>
                    <div className="grid-col-1">
                        <div className='flex justify-between items-start gap-[10px] mt-[-4px]'>
                            <PaymentReceipt />
                        </div>
                    </div>
                    <TextArea   name="studentCalculatorModal.note" type="text" value={studentCalculatorModal?.note}  title={lang?.pay_note} />
                   
                </div>
                <div className='flex mt-[20px] justify-between'>
                    <button disabled={btnDisabled} onClick={this.changePage.bind(this, "enroll_params")} className="h-[25px] bg-orange p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                    Back
                    </button>
                    <button disabled={btnDisabled} onClick={this.saveEnrollBtn.bind(this, studentCalculatorModal, lessonModes)} className="h-[25px] bg-blue p-[0_8px] inline-flex items-center text-[12px] font-semibold text-white rounded-[5px]">
                        Save & Enroll
                    </button>
                </div>
           </div>
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    studentCalculatorModal: state.Data.studentCalculatorModal,
    lang: state.Data.lang,
    lessonModes: state.Data.lessonModes,
    oldestEnrollCount: state.Data.oldestEnrollCount,
});
const mapDispatchToProps = {changeStateValue, insertNewData, getTeacherSalary, resetStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(PaymentParams)
