import React, { Component } from 'react'
import Header from '../../components/header/Header'
import StatisticCard from '../../components/cards/StatisticCard'
import { connect } from 'react-redux'
import { getOfficeEnrolledStudent, getTypeOfStudents, getOfficeExamTakes, getOfficeTeacherManagerData, getDob, getStatRef } from '../../actions/StaffAction'
import moment from 'moment'
import { examTakens, getMonthyStatus, getStEnrolled, getStatRefFormat, typeOfSt } from '../../actions/getGeneralStats'
import MonthlyCalendar from '../../components/MonthlyCalendar'
import DateOfBirthCard from '../../components/cards/DateOfBirthCard'
export class GeneralStats extends Component {
    state={
        dobs:[],
        statRef:[]
    }
    componentDidMount(){
        this.props.getOfficeEnrolledStudent(moment(new Date()).format("YYYY"));
        this.props.getTypeOfStudents();

        this.props.getOfficeExamTakes(moment(new Date()).format("YYYY-MM"))
        this.props.getOfficeTeacherManagerData(moment(new Date()).format("YYYY-MM"));
        this.props.getDob()
        .then(resp=>{
            this.setState({dobs:resp})
        })

        this.props.getStatRef()
        .then(resp=>{
            this.setState({statRef:resp})
        })
        window.scrollTo(0,0)
    }
    changeYearBtn=(date)=>{
        this.props.getOfficeEnrolledStudent(date);
    }
    changeMonthBtn=(date)=>{
        this.props.getOfficeExamTakes(date)
        this.props.getOfficeTeacherManagerData(date)
    }
   
  render() {
    const {officeEnrollStudents, typeOfStudents, officeExamTakens, officeTeacherManagerData, lang} = this.props;
    const {dobs, statRef} = this.state;
    const whereHear = [
        { label: "Onlayn Axtarış (məs., Google, Bing)", value: 1 },
        { label: "Sosial Media (məs., Facebook, Instagram, Twitter)", value: 2 },
        { label: "CELT veb saytı", value: 3 },
        { label: "Dost və ya Ailə Üzvü", value: 4 },
        { label: "Onlayn Reklam", value: 5 },
        { label: "Çap Reklamı", value: 6 },
        { label: "Televiziya və ya Radio Reklamı", value: 7 },
        { label: "Təhsil sərgisi və ya seminar", value: 8 },
        { label: "Broşur və ya Flyerlar", value: 9 },
        { label: "Tövsiyə vasitəsilə", value: 10 },
        { label: "Digər", value: 11 },
    ]
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="gen_stats" />
            <div className='flex max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <MonthlyCalendar onSelect={this.changeMonthBtn.bind(this)} />
            </div>
            <div className='w-full grid-full-320 mt-[42px] items-start'>
                {
                    officeEnrollStudents !== ''?
                    <StatisticCard onChangeYear={this.changeYearBtn.bind(this)} yearlyCalendar={true} data={getStEnrolled(officeEnrollStudents)} title={lang?.st_enrolled} />:null
                }
                {
                    officeExamTakens !== "" && officeExamTakens !== "" && typeOfStudents !== ""?
                    <StatisticCard data={[...getMonthyStatus(officeTeacherManagerData, officeExamTakens, ), ...typeOfSt(typeOfStudents)]} title={lang?.st_mode} /> :null
                }
                {
                    statRef.length !=0?
                    <StatisticCard data={getStatRefFormat(statRef, whereHear)} title={lang?.references_stats} /> :null
                }
                {/* {
                    officeExamTakens !== "" && officeTeacherManagerData !== ""?
                    <StatisticCard data={examTakens(officeExamTakens, officeTeacherManagerData, )} title={lang?.ex_taken} /> :null
                } */}
                {/* {
                    officeExamTakens !== "" && officeExamTakens !== ""?
                    <StatisticCard data={getMonthyStatus(officeTeacherManagerData, officeExamTakens, )} title={lang?.monthly_status} /> :null
                } */}
                {
                    dobs.length !=0?
                    <DateOfBirthCard dobs={dobs} />:null
                }
            </div>
        </div>
    )
  }
}

const mapStateToProps = (state) => ({
    officeEnrollStudents: state.Data.officeEnrollStudents,
    typeOfStudents: state.Data.typeOfStudents,
    officeExamTakens: state.Data.officeExamTakens,
    officeTeacherManagerData: state.Data.officeTeacherManagerData,
    user: state.Data.user,
    lang: state.Data.lang,
});
const mapDispatchToProps = {getOfficeEnrolledStudent, getTypeOfStudents, getOfficeExamTakes, getOfficeTeacherManagerData, getDob, getStatRef}
export default connect(mapStateToProps, mapDispatchToProps)(GeneralStats)
