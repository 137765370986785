import React, { Component } from 'react'

export class ExamRightSvg extends Component {
  render() {
    return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlSpace="preserve"
        width={22}
        height={22}
        viewBox="0 0 155.139 155.139"
      >
        <path
          d="M155.139 77.566 79.18 1.596v44.382H0v63.177h79.18v44.387z"
          style={{
            fill: "#fff",
          }}
        />
      </svg>
    )
  }
}

export default ExamRightSvg
