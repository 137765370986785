import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getWeeklyAuditCoachOne } from '../../actions/CoachAction';
import { getCoachStatus } from '../../actions/getRole';
import moment from 'moment';
import CloseSvg from '../../components/svg/CloseSvg';
import CoachPartCard from '../../components/dashboard/CoachPartCard';
import {withHooks} from "../../actions/withHooks"
export class AuditOneWeeklyCoach extends Component {
    state={
        loader:true,
        weeklyData:[]
    }
    componentDidMount(){
        this.props.getWeeklyAuditCoachOne(this.props.params.id)
        .then(resp=>{
            this.setState({loader:false})
        })
    }
    getPercentage(companyCoach, endOfCourseData=[]){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success + endOfCourseData.length;
        console.log(errors)
        return {
            total: total,
            errors: Math.round(((errors + endOfCourseData.length)*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
        }
    } 
    getManuallyCoach=()=>{
        this.setState({loader:true});
        this.props.getWeeklyAuditCoachOne("manual")
        .then(resp=>{this.setState({loader:false})})
    }
    getCoachStatsAll(companyCoach){
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    start_date: x?.start_date,
                    end_date: x?.end_date,
                    // status:x?.exam?.status?.status
                    status: getCoachStatus(x),
                }

                arr.push(info)
            }
        }))
        return arr
    }

    getView=(data)=>{
        this.setState({weeklyData:data});
    }
    closeBackground(e){
        if(typeof e.target.className == 'string' && e.target.className.includes("background_shadow")){
          this.setState({weeklyData:[]})
        }
      }
  render() {
    const {auditWeeklyCoachOne, lang, endOfCourseData} = this.props;
    const {loader, weeklyData} = this.state;
    // console.log(this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.errors)
    return (
      <div className=' max-[1100px]:mt-[120px] max-[800px]:mt-[150px] pl-[20px] pr-[20px] mt-[50px] mb-[-40px]'>
        {
            this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.total !==0?
            <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                <div className='flex justify-between items-center'>
                    <h2>{lang?.weekly_healthy_tracker}</h2>
                   <div className='flex gap-[20px] items-center'>
                   <button className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold' onClick={this.getView.bind(this, auditWeeklyCoachOne)}>{lang?.view}</button>
                   {/* <button className='bg-blue p-[4px_7px] rounded-[6px] text-white text-[12px] font-semibold' onClick={this.getManuallyCoach.bind(this)}>{lang?.coach_refresh_btn}</button> */}
                   </div>
                </div>
                {
                loader?
                <div  className={`skeleton skeleton-loading `}>
                    <h4 className={`skeleton-description h-[30px_!important]`}></h4>
                </div>:
                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center '>
                    
                    <div style={{ width: `${this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.errors}%`}} className='h-[30px] rounded-l-[10px] w-[20%] bg-[#fd2e63] flex items-center justify-end pr-[10px]'>
                        <span className='text-[14px] text-white'>{this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.errors}%</span>
                    </div>
                    <div style={{ width: `${this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.warnings}%`}} className='h-[30px]  w-[20%] bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                        <span className='text-[14px] text-white'>{this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.warnings}%</span>
                    </div>
                    <div style={{ width: `${this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.success}%`}} className='h-[30px] rounded-r-[10px] w-[20%] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                        <span className='text-[14px] text-white'>{this.getPercentage(auditWeeklyCoachOne, endOfCourseData)?.success}%</span>
                    </div>
                </div>  
            }
          
            
          </div>:null
        }
        {
            weeklyData?.length !=0?
            <div onClick={this.closeBackground.bind(this)} className='bg-[rgba(0,0,0,0.3)] background_shadow fixed top-0 left-0 w-full h-screen flex justify-center items-center z-[999]'>
            <div className='w-[80%] bg-white p-[20px] rounded-[10px] shadow max-h-[70vh] overflow-auto max-[400px]:w-[90%]'>
                <div className='flex justify-end'>
                    <button onClick={()=>this.setState({weeklyData:[]})}>
                        <CloseSvg color="#000" />
                    </button>
                </div>
                <div className='bg-white p-[20px] flex flex-col gap-[20px] rounded-[20px] max-[500px]:p-[0px]'>
                    <h2>{lang?.coach_mustbe_done}</h2>
                    {
                        this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 2).length!=0?
                        <div className='grid-full-320 '>
                            {
                                    this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 2)?.map((data, i)=>{
                                        return(
                                           
                                            <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id} student_name={data?.student_name} teacher_name={data?.teacher_name} course_title={data?.course_title}  status={data?.status}  />
                                        )
                                    })
                                }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                    
               </div>

               <div className=' bg-white p-[20px] mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_need_to_be_done}</h2>
                    {
                        this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 1).length!=0?
                        <div className='grid-full-320'>
                        {
                            this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 1)?.map((data, i)=>{
                                return(
                                    <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title}  status={data?.status}  />
                                )
                            })
                        }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                   
               </div>
               <div className=' bg-white p-[20px]  mt-[20px] flex flex-col gap-[20px] rounded-[20px]'>
                    <h2>{lang?.coach_already_done}</h2>
                    {
                        this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 0).length!=0?
                        <div className='grid-full-320'>
                        {
                            this.getCoachStatsAll(auditWeeklyCoachOne).filter(a=>a.status == 0)?.map((data, i)=>{
                                return(
                                    <CoachPartCard start_date={moment(data?.start_date)?.format("DD/MM/YYYY")} end_date={moment(data?.end_date)?.format("DD/MM/YYYY")} key={i} data={data?.statistics} student_id={data?.student_id}  teacher_name={data?.teacher_name}  student_name={data?.student_name} course_title={data?.course_title}  status={data?.status} />
                                )
                            })
                        }
                        </div>:
                        <p>{lang?.coach_outstanding}</p>
                    }
                    
               </div>
                
            </div>
        </div>:null
        }
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    auditWeeklyCoachOne: state.Data.auditWeeklyCoachOne,
    lang: state.Data.lang,
    endOfCourseData: state.Data.endOfCourseData,
});
const mapDispatchToProps = {getWeeklyAuditCoachOne}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AuditOneWeeklyCoach))


