import React, { Component } from 'react'
import { connect } from 'react-redux';
import { getAuditCoach, getEndOfCourseData } from '../../actions/CoachAction';
import { Link } from 'react-router-dom';
import { getCompanies } from '../../actions/AdminActions';
import Header from '../../components/header/Header';
import { getCoachStatus } from '../../actions/getRole';
export class AudotMonthlCoach extends Component {
    state={
        type:'all'
    }
    componentDidMount(){
        this.props.getCompanies(10)
        .then(resp=>{
            this.setState({type:'all'})
        })
        this.props.getAuditCoach(1)
        .then(resp=>{
            this.setState({type:'coach'})
        })
        this.props.getEndOfCourseData();
    }
    getCoachStatsAll(companyCoach){
        // console.log(companyCoach)
        let arr = [];
        companyCoach.forEach((item=>{
            for(const x of item?.statistics){
                let stats = [];
                if(x?.exam !=""){
                    stats.push({
                        ...x.exam,
                        title:"Exam",
                        type:'exam'
                    })
                }
                if(x?.assessments !=""){
                    stats.push({
                        ...x.assessments,
                        title:"RP.",
                        type:'assessments'
                    })
                }
                if(x?.essay !=""){
                    stats.push({
                        ...x.essay,
                        title:"Es.",
                        type:'essay'
                    })
                }
                if(x?.speaking !=""){
                    stats.push({
                        ...x.speaking,
                        title:"Sp.",
                        type:'speaking'
                    })
                }
                if(x?.homework !=""){
                    stats.push({
                        ...x.homework,
                        title:"PT",
                        type:'pt'
                    })
                }
                if(x?.speaking_practice !=""){
                    stats.push({
                        ...x.speaking_practice,
                        title:"Sp. P",
                        type:'sp_practice'
                    })
                }
                
                let info={
                    course_title: x.course_title,
                    lesson_mode: x.lesson_mode,
                    student_name: item?.full_name,
                    teacher_name: x?.teacher?.full_name,
                    student_id:item?.id,
                    statistics: stats,
                    // status:x?.exam?.status?.status
                    status: getCoachStatus(x),
                }

                arr.push(info)
            }
        }))
        return arr
    }
  
    getPercentage(companyCoach=[], endOfCourseData=[]){
        let errors =  this.getCoachStatsAll(companyCoach).filter(a=>a.status == 2).length;
        let warnings = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 1).length;
        let success = this.getCoachStatsAll(companyCoach).filter(a=>a.status == 0).length;
        let total = errors + warnings + success + endOfCourseData.length;
        let stCount = errors + warnings + success;
        return {
            total: total,
            errors: Math.round(((errors + endOfCourseData.length)*100)/total),
            warnings: Math.round((warnings*100)/total),
            success: Math.round((success*100)/total),
            stCount: stCount
        }
    } 
  render() {
    const {adminCompanies, endOfCourseData} = this.props;
    const {type} = this.state;
    let allData = [];
    adminCompanies.forEach(data=>{
        if(data?.id !== 32 && 
            data?.id !== 29 &&
            data?.id !== 28 &&
            data?.id !== 27 &&
            data?.id !== 26 &&
            data?.id !== 22 &&
            data?.id !== 25 &&
            data?.id !== 24 &&
            data?.id !== 19 &&
            data?.id !== 34 &&
            data?.id !== 35 &&
            data?.id !== 31 &&
            data?.id !== 18 &&
            data?.id !== 17 
        ){
            allData.push(data)
        }
    })
    let stats = []
    if(type == 'all'){
        stats = allData
    }else{
        stats = [...allData].sort((x, y)=> { return (this.getPercentage(y?.students, endOfCourseData.find(st=>st?.id == y?.id)?.stats)?.success + this.getPercentage(y?.students, endOfCourseData.find(st=>st?.id == y?.id)?.stats)?.warnings) - (this.getPercentage(x?.students, endOfCourseData.find(st=>st?.id == x?.id)?.stats)?.success + this.getPercentage(x?.students, endOfCourseData.find(st=>st?.id == x?.id)?.stats)?.warnings)})
    }
    // console.log(endOfCourseData)
    return (
      <div className=' w-full  pl-[27px] pr-[27px]'>
        <Header title='Monthly Coach' />
       <div className='mt-[40px] max-[1100px]:mt-[150px] max-[800px]:mt-[180px]  border border-blue-600 rounded-[10px] p-[10px]'>
        <h2 className='txt-blue text-[25px] font-bold text-center flex gap-[17px] justify-center'>Top Tier 1 <span className='text-green-700'>(50+)</span></h2>
        <div className='grid-full-250  items-start p-[20px] rounded-[20px] mt-[10px]'>
            {
                stats.map((data, i)=>{
                    if(data?.id !== 32 && 
                        data?.id !== 29 &&
                        data?.id !== 28 &&
                        data?.id !== 27 &&
                        data?.id !== 26 &&
                        data?.id !== 22 &&
                        data?.id !== 25 &&
                        data?.id !== 24 &&
                        data?.id !== 19 &&
                        data?.id !== 31 &&
                        data?.id !== 18 &&
                        data?.id !== 17 
                    ){
                        if(this.getPercentage(data?.students, [])?.stCount >=50){
                            return(
                                <Link target='_blank' to={`/audit-company/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                    <h3>{data?.name}</h3>
                                    {
                                        data?.students?
                                        <div>
                                            <div className='bg-white  rounded-[20px] '>
                                                {/* <h2>Quality Health Tracker</h2> */}
                                                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%`}} className='h-[30px] min-w-[30px] rounded-l-[10px]  bg-[#fd2e63] flex items-center justify-end '>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%</span>
                                                    </div>
                                                    {/* <div style={{ width: `${this.getPercentage(data?.students)?.warnings}%`}} className='h-[30px] min-w-[30px]  bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students)?.warnings}%</span>
                                                    </div> */}
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%`}} className='h-[30px] min-w-[30px] rounded-r-[10px] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%</span>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>:
                                        <div>
                                            <h3 className='txt-blue text-center mt-[10px] font-semibold'>Loading...</h3>
                                        </div>
                                    }
                                   
                                </Link>
                            )
                        }
                       
                    }
                    
                })
            }
        </div>
       </div>
       <div className='mt-[30px]  border border-blue-600 rounded-[10px] p-[10px]'>
        <h2 className='txt-blue text-[25px] font-bold text-center flex gap-[17px] justify-center'>Top Tier 2 <span className='text-green-700'>(20+)</span></h2>
        <div className='grid-full-250  items-start p-[20px] rounded-[20px] mt-[10px]'>
            {
                stats.map((data, i)=>{
                    if(data?.id !== 32 && 
                        data?.id !== 29 &&
                        data?.id !== 28 &&
                        data?.id !== 27 &&
                        data?.id !== 26 &&
                        data?.id !== 22 &&
                        data?.id !== 25 &&
                        data?.id !== 24 &&
                        data?.id !== 19 &&
                        data?.id !== 31 &&
                        data?.id !== 18 &&
                        data?.id !== 17 
                    ){
                        if(this.getPercentage(data?.students, [])?.stCount >=20 && this.getPercentage(data?.students, [])?.stCount < 50){
                            return(
                                <Link target='_blank' to={`/audit-company/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                    <h3>{data?.name}</h3>
                                    {
                                        data?.students?
                                        <div>
                                            <div className='bg-white  rounded-[20px] '>
                                                {/* <h2>Quality Health Tracker</h2> */}
                                                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%`}} className='h-[30px] min-w-[30px] rounded-l-[10px]  bg-[#fd2e63] flex items-center justify-end '>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%</span>
                                                    </div>
                                                    {/* <div style={{ width: `${this.getPercentage(data?.students)?.warnings}%`}} className='h-[30px] min-w-[30px]  bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students)?.warnings}%</span>
                                                    </div> */}
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%`}} className='h-[30px] min-w-[30px] rounded-r-[10px] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%</span>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>:
                                        <div>
                                            <h3 className='txt-blue text-center mt-[10px] font-semibold'>Loading...</h3>
                                        </div>
                                    }
                                   
                                </Link>
                            )
                        }
                       
                    }
                    
                })
            }
        </div>
       </div>
       <div className='mt-[30px]  border border-blue-600 rounded-[10px] p-[10px]'>
        <h2 className='txt-blue text-[25px] font-bold text-center flex gap-[17px] justify-center'>Top Tier 3</h2>
        <div className='grid-full-250  items-start p-[20px] rounded-[20px] mt-[10px]'>
            {
                stats.map((data, i)=>{
                    if(data?.id !== 32 && 
                        data?.id !== 29 &&
                        data?.id !== 28 &&
                        data?.id !== 27 &&
                        data?.id !== 26 &&
                        data?.id !== 22 &&
                        data?.id !== 25 &&
                        data?.id !== 24 &&
                        data?.id !== 19 &&
                        data?.id !== 31 &&
                        data?.id !== 18 &&
                        data?.id !== 17 
                    ){
                        if(this.getPercentage(data?.students, [])?.stCount  < 20){
                            return(
                                <Link target='_blank' to={`/audit-company/${data?.id}/${data?.name}`} key={i} className='shadow p-[20px] rounded-[10px] bg-white'>
                                    <h3>{data?.name}</h3>
                                    {
                                        data?.students?
                                        <div>
                                            <div className='bg-white  rounded-[20px] '>
                                                {/* <h2>Quality Health Tracker</h2> */}
                                                <div className='w-full h-[30px] rounded-[10px] bg-gray-100 flex items-center mt-[10px]'>
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%`}} className='h-[30px] min-w-[30px] rounded-l-[10px]  bg-[#fd2e63] flex items-center justify-end '>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.errors}%</span>
                                                    </div>
                                                    {/* <div style={{ width: `${this.getPercentage(data?.students)?.warnings}%`}} className='h-[30px] min-w-[30px]  bg-[#ff5f24] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students)?.warnings}%</span>
                                                    </div> */}
                                                    <div style={{ width: `${this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%`}} className='h-[30px] min-w-[30px] rounded-r-[10px] bg-[#51bb25] flex items-center justify-end pr-[10px]'>
                                                        <span className='text-[14px] text-white'>{this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.success + this.getPercentage(data?.students, endOfCourseData.find(x=>x?.id == data?.id)?.stats)?.warnings}%</span>
                                                    </div>
                                                </div>  
                                            </div>
                                        </div>:
                                        <div>
                                            <h3 className='txt-blue text-center mt-[10px] font-semibold'>Loading...</h3>
                                        </div>
                                    }
                                   
                                </Link>
                            )
                        }
                       
                    }
                    
                })
            }
        </div>
       </div>
      </div>
    )
  }
}
const mapStateToProps = (state) => ({
    adminCompanies: state.Data.adminCompanies,
    endOfCourseData: state.Data.endOfCourseData,
});
const mapDispatchToProps = {getAuditCoach, getCompanies, getEndOfCourseData}
export default connect(mapStateToProps, mapDispatchToProps)(AudotMonthlCoach)


