import React, { Component } from 'react'
import { connect } from 'react-redux';
import {withHooks} from "../../../actions/withHooks"
import Header from '../../../components/header/Header';
import ExamParents from './ExamParents';
import HeaderButtons from '../../../components/HeaderButtons';
import { changeStateValue } from '../../../redux/MainReducer';
import { confirmAlert } from '../../../actions/SweetAlerts';
import ExamInfo from './ExamInfo';
import ParentModal from './modals/ParentModal';
import QuestionModal from './modals/QuestionModal';
import RoboQuestionModal from './modals/RoboQuestionModal';
import RoboPreviewModal from './modals/RoboPreviewModal';
import PoolingExamParents from './PoolingExamParents';
export class AdminAddEditExams extends Component {
  state={

  }
  componentDidMount(){
    window.scrollTo(0,0)
  }
  changeType=(data)=>{
    if(data?.id === '1'){
      confirmAlert("warning", "Are you sure?", "Are you sure to do this? timing will be disabled and if you revert back to Exam, you have to add them again.", "Yes, change it!")
      .then(resp=>{
        if(resp === "success"){
          this.props.changeStateValue({ name:"adminExamData.type",  value: data?.id  });
          this.props.changeStateValue({ name:"adminExamData.duration_minutes",  value: '0'});
          this.props.changeStateValue({ name:"adminExamData.retake_minutes",  value: '0'});
          this.props.changeStateValue({ name:"adminExamData.retake_time",  value: '0'});
        }
      })
      return
    }
    this.props.changeStateValue({
      name:"adminExamData.type",
      value: data?.id
    })
    this.props.changeStateValue({ name:"adminExamData.duration_minutes",  value: ''});
    this.props.changeStateValue({ name:"adminExamData.retake_minutes",  value: ''});
    this.props.changeStateValue({ name:"adminExamData.retake_time",  value: ''});
  }
  render() {
    const {adminExamData, adminExamModalData, modalRoboQuestion, modalRoboPrev} = this.props;
    // console.log(adminExamData)
    return (
      <div className='w-full pl-[27px] pr-[27px]'>
        <Header title={`${adminExamData?.id === ""? "Add":"Edit"} ${adminExamData?.type==0?"Exam":"HW"}`} />
        <div className='mt-[20px]'>
          <HeaderButtons selected={+adminExamData?.type} buttons={[{title:"Exam Details", id:"0"}, {title:"HW Details", id:"1"}]} onClick={this.changeType.bind(this)} />
        </div>
        <ExamInfo  />
        {
         adminExamData?.exam_type == "normal" && adminExamData?.id !== ""?
          <ExamParents />:null
        }
        {
           adminExamData?.exam_type == "pooling_standart" && adminExamData?.id !== ""?
          <PoolingExamParents />:null
        }
        {adminExamModalData?.position ? <ParentModal examId={this.props.params.id} />:null}
        {adminExamModalData?.questionPositon ?  <QuestionModal examId={this.props.params.id}  />:null}
        {modalRoboQuestion?.position ?  <RoboQuestionModal examId={this.props.params.id}  />:null}
        {modalRoboPrev.questions.length !==0? <RoboPreviewModal />:null}
        
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
  adminExamData: state.Data.adminExamData,
  adminExamModalData: state.Data.adminExamModalData,
  modalRoboQuestion: state.Data.modalRoboQuestion,
  modalRoboPrev: state.Data.modalRoboPrev,
});
const mapDispatchToProps = { changeStateValue }
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(AdminAddEditExams))
