import React, { Component } from 'react'
import Header from '../../components/header/Header'
import EmptyPage from '../../components/EmptyPage'
import { connect } from 'react-redux';
import { getExams, getToeflExamData, insertNewData } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import Card2 from '../../components/cards/Card2';
import ExamStartAuthModal from '../../components/modals/ExamStartAuthModal';
import { changeStateValue } from '../../redux/MainReducer';
import { withHooks } from '../../actions/withHooks';
import { mixinAlert } from '../../actions/SweetAlerts';
import ResponseWaitModal from '../../components/modals/ResponseWaitModal';
import socket from '../../actions/socketIo';

export class Exams extends Component {
  componentDidMount(){
   
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.props.getExams(data.sub);
    }
    window.scrollTo(0,0)
 
    socket.on("support_response", (data)=>{
      this.props.changeStateValue({name:"waitingSupportResponse", value:false})
      const response = JSON.parse(data);
      if(response?.student_id == this.props.user?.id){
        if(response?.status=='accept'){
          mixinAlert("success", "Exam permission granted")
          this.props.insertNewData("exam-auth-key", {exam_id:response?.exam_id, code: response?.code})
          .then(resp=>{
            if(resp=="success"){
              if(response.exam_type == "normal"){
                this.props.navigate(`/exam-start/${response?.exam_id}`)
              }else{
                this.props.navigate(`/start-pooling-exams/${response?.exam_id}`)
              }
            }else{
              mixinAlert("error", "Something went wrong")
            }
          })
        }else{
          mixinAlert("error", "Exam permission rejected")
        }
      }
    })
  }
// componentWillUnmount(){
//   socket.off("connect");
//   socket.off("support_response")
//   socket.off("support_request")
//   socket.disconnect()
// }
  startToefl(data){
    if(data?.check_toefl === "1"){
      if(data?.toefl_exam_id === null){
        this.props.getToeflExamData(data?.exam_id, data?.id)
        .then(resp=>{
          if(resp?.type === "writing"){
            window.open(`/exam-start/${resp?.id}/toefl-writing`)
          }else if(resp?.type === "speaking"){
            window.open(`/exam-start/${resp?.id}/toefl-speaking`)
          }
        })
      }else{
        if(data?.toefl_exam_id?.type === "writing"){
          window.open(`/exam-start/${data?.toefl_exam_id?.id}/toefl-writing`)
        }else if(data?.toefl_exam_id?.type === "speaking"){
          window.open(`/exam-start/${data?.toefl_exam_id?.id}/toefl-speaking`)
        }
      }
    }else{
      if(data?.lesson?.type == "end"){
        const params = {
          exam: data?.exam?.title,
          course: data?.course?.title,
          student: this.props.user?.full_name,
          company_id: this.props.user?.company_id,
          exam_id: data?.exam_id,
          student_id: data?.student_id,
          code: data?.auth_code,
          exam_type: data?.exam?.exam_type,
          unit_type:data?.lesson?.type
         }
         socket.emit("support_request", JSON.stringify(params));
         this.props.changeStateValue({name:"waitingSupportResponse", value:true})
         return
      }
     
      if(data?.auth_code){
        this.props.changeStateValue({
          name:"authCodeModal",
          value:{
            position:true,
            code:'',
            existsCode:data?.auth_code,
            exam_id:data?.exam?.id,
            data: data
          }
        })
      }else{
        window.open(`exam-start/${data?.exam?.id}`)
      }
    }
    
  }
  render() {
    const {exams, authCodeModal, waitingSupportResponse} = this.props;
    // console.log(exams)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="gen_exams" />
            <div className={`mt-[42px] ${exams.length === 0?'':'grid-full-205'} max-[1100px]:mt-[120px] max-[800px]:mt-[180px]`}>
              {
                exams.length ===0?
                <EmptyPage />:
                exams?.map((data, i)=>{
                  return(
                    <Card2
                      key={i}
                      title={data?.exam?.title}
                      subTitle={data?.course?.title}
                      // btnUrl={data?.check_toefl === "1"? null: `/exam-start/${data?.exam?.id}`}
                      timer={data?.timer_start}
                      minute={data?.exam?.duration_minutes}
                      onClickBtn={this.startToefl.bind(this,data)}
                      // onClickBtn={data?.check_toefl === "1"?this.startToefl.bind(this,data):()=>{}}
                    />
                  )
                })
              }
            </div>
            {
              authCodeModal?.position?
               <ExamStartAuthModal /> :null
            }
            {waitingSupportResponse? <ResponseWaitModal />:null}
            {/**/}
        </div>
    )
  }
}
const mapStateToProps = (state)=>({
  exams: state.Data.exams,
  authCodeModal: state.Data.authCodeModal,
  user: state.Data.user,
  waitingSupportResponse: state.Data.waitingSupportResponse,
});
const mapDispatchToProps = {getExams, getToeflExamData, changeStateValue, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(Exams))
