import React, { Component } from 'react'
import Header from '../../../components/header/Header'
import HeaderButtons from '../../../components/HeaderButtons'
import { getPoolingExamQuestions, getExamSectionInfo } from '../../../actions/StaffAction'
import { connect } from 'react-redux'
import { withHooks } from '../../../actions/withHooks'
import DropSearch from '../../../components/DropSearch'
import { changeStateValue } from '../../../redux/MainReducer'
import ExamAutoAddModal from './ExamAutoAddModal'
import Card1 from '../../../components/cards/Card1'
import { bigAlert, confirmAlert, mixinAlert } from '../../../actions/SweetAlerts'
import { deleteData, insertNewData } from '../../../actions/MainAction'
import FullParentModal from './FullParentModal'
export class ExamQuestionCreatePage extends Component {
    state={
        info:'',
        activePage:0,
        active_type: '1',
        level:''
    }
    componentDidMount(){
        this.props.getExamSectionInfo(this.props.params.section_id)
        .then(resp=>{
            this.setState({info:resp})
        })
        this.props.getPoolingExamQuestions(this.props.params.section_id, this.state.active_type, this.state.level)
   
       
        // console.log(checkSearch)
        // if(typeof window?.MathJax !== "undefined"){
        //     window.MathJax.typeset()
        //   }
    }

    getAfterChange=()=>{
        this.props.getPoolingExamQuestions(this.props.params.section_id, this.state.active_type, this.state.level)
    }
    levelOptions=()=>{
        return [
            {
                label:"All",
                value:""
            },
            {
                label:"Easy",
                value:"easy"
            },
            {
                label:"Medium",
                value:"medium"
            },
            {
                label:"Hard",
                value:"hard"
            },
        ]
    }

    typeOptions=()=>{
        return [
            {
                label:"Clear",
                value:""
            },
            {
                label:"Grammar",
                value:"grammar"
            },
            {
                label:"Writing",
                value:"writing"
            },
            {
                label:"Reading",
                value:"reading"
            },
            {
                label:"Listening",
                value:"listening"
            },
            {
                label:"Speaking",
                value:"speaking"
            },
        ]
    }
    
    onDeleteData=(id)=>{
        confirmAlert("warning", "Are you sure?", "You won't be able to revert this!")
        .then(alertResp=>{
            if(alertResp === "success"){
                this.props.deleteData(`pooling-exam-questions/${id}`)
                .then(resp=>{
                    if(resp==="success"){
                        bigAlert("Success!", "Question Deleted successfully!", "success");
                        this.props.getPoolingExamQuestions(this.props.params.section_id, this.state.active_type, this.state.level)
                    }else{
                        bigAlert("Error!", "Something went wrong!", "error")
                    }
                })
            }
        })
    }
    addSingleBtn=(type="single_choice")=>{
        let data = {
            ...this.props.poolingExamAutoAdd,
            position:true,
            category_id: this.props.params.id,
            sub_category_id: this.props.params.sub_cat_id,
            section_id: this.props.params.section_id,
            question_type:type,
            answer_options:[
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
                {
                    id:'',
                    title:'',
                    is_correct:0,
                    question_id:'',
                    type:type,
                    deleted:false
                },
            ]
        }
        this.props.changeStateValue({
            name:'poolingExamAutoAdd',
            value: data
        })
    }
    addFreeTextBtn=(type)=>{
        let data = {
            ...this.props.poolingExamAutoAdd,
            position:true,
            category_id: this.props.params.id,
            sub_category_id: this.props.params.sub_cat_id,
            section_id: this.props.params.section_id,
            question_type:type,
            answer_options:[]
        }
        this.props.changeStateValue({
            name:'poolingExamAutoAdd',
            value: data
        })
    }

    openModal=(data)=>{
        // console.log(data)
        this.props.changeStateValue({
            name:'poolingExamAutoAdd',
            value: {
                ...data,
                position:true,
                answer_options: data?.answers
            }
        })
    }

    getDataByStatus=(data, index)=>{
        this.setState({activePage:index, active_type:data?.id});
        this.props.getPoolingExamQuestions(this.props.params.section_id,data?.id, this.state.level)
    }

    changeStatus=(data, status)=>{
        // console.log(status)
        let formData = new FormData();
        formData.append("active", status==1?"0":'1');
        formData.append("_method", "put")
        this.props.insertNewData(`changeExamStatus/${data?.id}`, formData)
        .then(resp=>{
            if(resp == "success"){
                mixinAlert("success", "Status changed successfully")
                this.props.getPoolingExamQuestions(this.props.params.section_id, this.state.active_type, this.state.level)
            }else{
                mixinAlert("error", "Something went wrong")
            }
        })
    }

    selectLevel=(data)=>{
        this.setState({
            level: data?.value
        });
        this.props.getPoolingExamQuestions(this.props.params.section_id, this.state.active_type, data?.value);
        this.props.changeStateValue({
            name:"poolingExamAutoAdd.level",
            value: data?.value
        })
       
    }

    addParentBtn=(data="")=>{
        this.props.changeStateValue({
            name:"poolingFullParentModal",
            value:{
                position:true,
                id: data?.id?data?.id:'',
                parent_id:'',
                category_id: this.props.params.id,
                sub_category_id: this.props.params.sub_cat_id,
                section_id: this.props.params.section_id,
                level: this.state.level,
                title:data?.title?data?.title:'',
                description:data?.description?data?.description:'',
                question_type:'parent_full',
                audio:data?.audio?data?.audio:'',
                video:data?.video?data?.video:'',
                image:data?.image?data?.image:'',
                audio_full_url:data?.audio_full_url?data?.audio_full_url:'',
                video_full_url:data?.video_full_url?data?.video_full_url:'',
                image_full_url:data?.image_full_url?data?.image_full_url:'',
                questions: data?.questions
            }
        })
    }
  render() {
    const {poolingExamQuestions, poolingExamAutoAdd, user, poolingFullParentModal} = this.props;
    const {info, activePage, level, active_type} = this.state;
    // console.log(poolingExamQuestions)
    // console.log("salam")
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title={info !==""?info?.sub_category?.category?.title+" / "+info?.sub_category?.title+ " / " +info?.title:""} />
            <div className='flex justify-between mt-[40px] max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <div className='white-search flex items-center gap-[20px]'>
                    {
                        this.levelOptions()?.map((data, i)=>{
                            return(
                                <button onClick={this.selectLevel.bind(this, data)} className={`text-[12px] p-[4px_8px] rounded-[5px] ${data?.value == level? "bg-blue text-white":"bg-white text-blue"}`} key={i}>{data?.label}</button>
                            )
                        })
                    }
                    {/* <DropSearch options={this.levelOptions()} name="poolingExamAutoAdd.level" placeholder="Select a level" value={poolingExamAutoAdd?.level}  title="Select a level" /> */}
                    {/* <DropSearch options={this.typeOptions()} name="poolingExamAutoAdd.type" placeholder="Select a type" value={poolingExamAutoAdd?.type}  title="Select a type" /> */}
                </div>
                {/* <div className='flex flex-col'>
                <div  dangerouslySetInnerHTML={{ __html:poolingExamQuestions[4]?.questions[0]?.description?poolingExamQuestions[4]?.questions[0]?.description:'' }}></div>
                 {
                    poolingExamQuestions[4]?.questions[0]?.answers?.map((x, i)=>{
                        return(
                            <div>{x?.title}</div>
                        )
                    })
                 }
                </div> */}
                <div className='flex items-center gap-[20px] '>
                    <button onClick={this.addParentBtn.bind(this)} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                       Add Q Parent
                    </button>
                    <button onClick={this.addSingleBtn.bind(this, "single_choice")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                        Single Choice
                    </button>
                    <button onClick={this.addSingleBtn.bind(this, "multiple_choice")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                        Multiple Choice
                    </button>
                    <button onClick={this.addFreeTextBtn.bind(this, "free_text")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                       Free text
                    </button>
                    <button onClick={this.addFreeTextBtn.bind(this, "drop_down")} className='bg-white text-[13px] p-[5px_8px] font-semibold shadow rounded-[7px]'>
                       Drop down
                    </button> 
                </div>
            </div>
            <div className='mt-[20px]'>
            {
                user?.role === "super_admin"?
                <HeaderButtons onClick={(data, index)=>this.getDataByStatus(data, index)} buttons={[{title: "Actives", id:'1'},{title: "All", id:''},{title: "Inactives", id:'0'}]} selected={activePage} />:<div></div>
            }
            </div>
            <div className='grid-full-250 mt-[20px]'>
            {
                poolingExamQuestions.map((data,i)=>{
                    return(
                        <Card1
                            key={i}
                            title={data?.category?.title}
                            exam={data?.title}
                            close={data?.sub_category?.title}
                            status={1}
                            statusBisible={false}
                            btn={true}
                            onCLickBtn={data?.question_type == "parent_full"? this.addParentBtn.bind(this, data): this.openModal.bind(this, data?.questions[0])}
                            // url={`/exam-pooling-questions/${data?.id}`}
                            // blank={true}
                            activeStatus={data?.active}
                            adminActiveVisible={user?.role === "super_admin"}
                            onClickChangeStatus={this.changeStatus.bind(this, data)}
                            adminDelete={user?.role === "super_admin"}
                            onDeleteBtn={this.onDeleteData.bind(this, data?.id)}
                            user={data?.user?.full_name}
                            course={data?.level}
                        />
                    )
                })
            }
            </div>
            {poolingExamAutoAdd?.position? <ExamAutoAddModal activePage={active_type} level={level} />:null}
            {poolingFullParentModal?.position? <FullParentModal getAfterChange={()=>this.getAfterChange()}  activePage={active_type} level={level}  />:null}
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    poolingExamQuestions: state.Data.poolingExamQuestions,
    poolingExamAutoAdd: state.Data.poolingExamAutoAdd,
    user: state.Data.user,
    poolingFullParentModal: state.Data.poolingFullParentModal,
});
const mapDispatchToProps = {getExamSectionInfo, getPoolingExamQuestions, changeStateValue, deleteData, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(withHooks(ExamQuestionCreatePage))
