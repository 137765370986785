import React, { Component } from 'react'
import MicRecordSvg from '../../svg/MicRecordSvg'
import wave from "../../../images/recordGif.gif"
import { endRecording, startRecording } from '../../../actions/ReorAudio';
import { connect } from 'react-redux';
import { changeStateValue } from '../../../redux/MainReducer';
import TrashSvg from '../../svg/TrashSvg';
let second = 0;
let minute = 0;
let interval;
export class Recorder extends Component {
    state={
        timer:'00:00',
        micPosition:'pause',
        audioBase64:''
    }
    getTimerFormst=(time)=>{
        if(time < 10){
            return `0${time}`;
        }else{
            return time;
        }
    }
    startTimer =  ()=>{
        this.setState({timer: `00:00`})
        interval = setInterval(()=>{
           
            if(second<60){
                second++;
            }else{
                minute++;
                second = 0;
            }
            this.setState({timer: `${this.getTimerFormst(minute)}:${this.getTimerFormst(second)}`})
        }, 1000);
       
    }
    stopTimer = ()=>{
        clearInterval(interval);
        second = 0;
        minute = 0;
    }
    startRecordingBtn(){
        this.startTimer();
        this.setState({micPosition:'record'});
        startRecording();
    }

    stopRecordBtn(){
        this.stopTimer();
        this.setState({micPosition:'pause'});
        const result = endRecording();
        this.setState({audioBase64: result?.audio_url})
        this.props.changeStateValue({name:'mainModal.recorder_file', value: result?.audioName})
    }

    // recording

    deleteAudio(){
        this.props.changeStateValue({name:'mainModal.recorder_file', value: ''})
        this.setState({audioBase64: ''})
    }
  render() {
    const {timer, micPosition, audioBase64} = this.state;
    const {lang} = this.props;
    return (
      <div className='flex mt-[20px] justify-center flex-col items-center gap-[20px]'>
        <div className='border border-blue-200 rounded-full p-[8px] h-[50px] flex items-center max-sm:w-[100%] gap-[7px]'>
            {
                micPosition == 'pause'?
                <button onClick={this.startRecordingBtn.bind(this)} className='bg-blue rounded-full inline-flex items-center h-[30px] w-[30px] min-w-[30px] justify-center'>
                    <MicRecordSvg />
                </button>:
                <button  onClick={this.stopRecordBtn.bind(this)}  className='bg-orange mic-start-btn rounded-full inline-flex items-center h-[30px] min-w-[30px] justify-center'>
                    <MicRecordSvg />
                </button>
            }
           {
            micPosition == 'pause'?
            <>
            <span className='txt-blue text-[12px] font-semibold'>{lang?.sp_record}</span>
            <span className='inline-block h-[1px] max-sm:w-[50%] w-[120px] bg-blue'></span>
            </>:
            <>
            <span className='txt-blue text-[12px] max-[320px]:hidden font-semibold'>{lang?.sp_recording}</span>
            <img className='h-[30px] max-sm:w-[50%] w-[120px]' alt='recording' src={wave} />
            </>
            
           }
            <span className='txt-blue text-[12px] font-semibold'>{timer}</span>
        </div>
        {
            audioBase64 !== ''?
            <div className='flex items-center gap-[10px]'>
                 <audio className='max-sm:w-[200px] w-[220px] h-[40px] max-[320px]:w-[150px]' controls src={audioBase64} />
                 <button onClick={this.deleteAudio.bind(this)} className='bg-red w-[35px] h-[35px] rounded-full inline-flex items-center justify-center'>
                    <TrashSvg />
                 </button>
            </div>:null
        }
       
      </div>
    )
  }
}
const mapStateToProps = (state) =>({
    lang: state.Data.lang
});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(Recorder)
