import React, { Component } from 'react'
import Header from '../../components/header/Header'
import Card1 from '../../components/cards/Card1'
import { connect } from 'react-redux';
import { getExamResults, getExamPartScore } from '../../actions/MainAction';
import {jwtDecode} from "jwt-decode";
import moment from 'moment';
import { changeStateValue } from '../../redux/MainReducer';
import LoadMoreBtn from '../../components/LoadMoreBtn';
import Search from '../../components/Search';
import EmptyPage from '../../components/EmptyPage';
import { getSATScore } from '../../actions/getRole';
export class ExamResults extends Component {
  state={
    results:[],
    search:'',
    studentId:''
  }
  async getData(studentId, page, keyword){
    return await this.props.getExamResults(studentId, page, keyword)
  }
  componentDidMount(){
    if(localStorage.getItem('userToken')){
      const data = jwtDecode(localStorage.getItem('userToken'));
      this.setState({studentId:data?.sub});
      this.getData(data?.sub, 1, '')
      .then(resp=>{
        this.setState({results:resp})
      })
    }
    window.scrollTo(0,0)
  }
  getToeflScore(part1, part2){
 
    if(part1 && part2){
        return +part1 + +part2 > 30? 30: +part1 + +part2
    }else{
        return 'Not checked yet'
    }
  }
  getToeflSpeakingScore(part1, part2, part3, part4){

      if(part1 && part2){
          return +part1 + +part2 + +part3 + +part4> 30? 30: +part1 + +part2 + +part3 + +part4
      }else{
          return 'Not checked yet'
      }
  }

  showResultBtn=(data)=>{
    this.props.getExamPartScore({id:data?.id, type:'exam', exam_type:'current'})
    .then(resp=>{
      if(resp == 'success'){
        this.props.changeStateValue({  name:'partScoreModal.position', value:true });
        this.props.changeStateValue({  name:'partScoreModal.title', value:data?.exam_title});
      }
    })
  }

  loadMore=(page)=>{
    this.getData(this.state.studentId, page, this.state.search)
    .then(resp=>{
        let oldData= page ===1? []:[...this.state.results]
      this.setState({results:[
        ...oldData,
        ...resp
      ]})
    })
  }

  onSearchData=(search)=>{
    this.setState({search:search})
    this.getData(this.state.studentId, 1, search)
    .then(resp=>{
      this.setState({results:[
        ...resp
      ]})
    })
  }
  onSelectStudent=(id)=>{
    this.setState({studentId:id})
    this.getData(id, 1, this.state.search)
    .then(resp=>{
      this.setState({results:[
        ...resp
      ]})
    })
  }


  render() {
    const {results} = this.state;
    const {user, lang} = this.props;
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header  title="exam_rws" />
            <div className='flex justify-center max-[1100px]:mt-[120px] max-[800px]:mt-[170px]'>
              <Search largWidth={true} searchVisible={true} onSelectStudent={this.onSelectStudent} student={user?.role == "parent"} onSearchData={this.onSearchData} />
            </div>
            {results?.length === 0? <div className='mt-[30px]'> <EmptyPage img={false} svg={true} title="No data found." subTitle="" /></div>:null}
            <div className='mt-[42px] grid-full-250'>
              {
                results?.map((data, i)=>{
                  if(data?.toefl){
                    return(
                      <Card1 
                        key={i}
                        title={data?.exam?.course?.title}
                        exam={data?.exam_title}
                        time={lang?.not_applicable}
                        grade={this.getToeflScore(data?.toefl?.part_1_score, data?.toefl?.part_2_score)}
                        status={this.getToeflScore(data?.toefl?.part_1_score, data?.toefl?.part_2_score) == 'Not checked yet'? 0:1}
                        date={moment(data?.updated_at).format("DD/MM/YYYY")}
                        btn={false}
                      />
                    )
                  }else if(data?.toefl_speaking){
                    return(
                      <Card1 
                        key={i}
                        title={data?.exam?.course?.title}
                        exam={data?.exam_title}
                        time={lang?.not_applicable}
                        grade={this.getToeflSpeakingScore(data?.toefl_speaking?.part_1_score, data?.toefl_speaking?.part_2_score, data?.toefl_speaking?.part_3_score, data?.toefl_speaking?.part_4_score)}
                        status={this.getToeflSpeakingScore(data?.toefl_speaking?.part_1_score, data?.toefl_speaking?.part_2_score, data?.toefl_speaking?.part_3_score, data?.toefl_speaking?.part_4_score)=='Not checked yet'?0:1}
                        date={moment(data?.updated_at).format("DD/MM/YYYY")}
                        btn={false}
                      />
                    )
                  }else{
                    return(
                      <Card1 
                        key={i}
                        title={data?.exam?.course?.title}
                        exam={data?.exam_title}
                        time={data?.spend_time}
                        grade={getSATScore(data.exam_title, +data.score)}
                        status={data?.status}
                        statusText={data?.status==1? lang?.pass: lang?.fail}
                        date={moment(data?.updated_at).format("HH:mm, DD/MM/YY")}
                        onCLickBtn={this.showResultBtn.bind(this, data)}
                    />
                    )
                  }
                  
                })
              }
            </div>
            {
                results?.length >=20?
                <LoadMoreBtn onLoadData={this.loadMore} count={results?.length} />:null
            }
        </div>
    )
  }
}
const mapStateToProps = (state) =>({
  examResults: state.Data.examResults,
  user: state.Data.user,
  lang: state.Data.lang,
});
const mapDispatchToProps = {getExamResults, getExamPartScore, changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(ExamResults)
