import React, { Component } from 'react'
import Logo from './Logo'
import Navigation from './Navigation'
import { connect } from 'react-redux';
import { changeStateValue } from '../../redux/MainReducer';
export class SideBar extends Component {
  closeNav=(e)=>{
    if(e.target.className === "sideBrBackground"){
      const nav  =document.querySelector('#navBtnCheckox');
      if(nav){
        nav.checked = false;
        this.props.changeStateValue({name:'navBtn', value:true})
      }
    }
  }
  render() {
    return (
    <>
    <input type='checkbox' className='hidden'  id='navBtnCheckox' />
     <div onClick={this.closeNav.bind(this)} className='sideBrBackground'>
       <div className='bg-white w-[251px] min-w-[251px] h-screen sidebar'>
        <Logo />
        <Navigation />
      </div>
     </div>
    </>
    )
  }
}
const mapStateToProps = (state) =>({});
const mapDispatchToProps = {changeStateValue}
export default connect(mapStateToProps, mapDispatchToProps)(SideBar)
