import React, { Component } from 'react'
import Header from '../../components/header/Header'
import { connect } from 'react-redux';
import { getComapnyExamException } from '../../actions/StaffAction';
import CardLine from '../../components/cards/CardLine';
import CardUserSvg from '../../components/svg/CardUserSvg';
import CardEmailSvg from '../../components/svg/CardEmailSvg';
import CardDateSvg from '../../components/svg/CardDateSvg';
import CardCheatingSvg from '../../components/svg/CardCheatingSvg';
import moment from 'moment';
import HeaderButtons from '../../components/HeaderButtons';
import { confirmAlert, mixinAlert } from '../../actions/SweetAlerts';
import { insertNewData } from '../../actions/MainAction';
export class CompanyExamException extends Component {
    state={
        status:1,
        exceptions:[],
        selected:0
    }
    componentDidMount(){
        this.props.getComapnyExamException(this.state.status)
        .then(resp=>{
            this.setState({exceptions: resp})
        })
    }
    changeStatus=(id, type)=>{
        confirmAlert("warning",'Are you sure?', "You won't be able to revert this!",'Yes, change it!')
        .then(respAlert=>{
            if(respAlert == "success"){
                let formData = new FormData();
                formData.append("status", type);
                formData.append("_method", "put");
                this.props.insertNewData(`student-ex-apply/${id}`, formData)
                .then(resp=>{
                    if(resp == "success"){
                       mixinAlert('success', `Your file has been ${type == '1'? 'accepted':'rejected'}`)
                       
                    }else{
                      mixinAlert("error", "Something went wrong")
                    }
                    this.props.getComapnyExamException(this.state.status)
                    .then(resp=>{
                        this.setState({exceptions: resp})
                    })
                })
            }
        })
    }
    changeDataBtn=(data, index)=>{
      
        this.setState({selected: +index, status:data?.id})
        this.props.getComapnyExamException(data?.id)
        .then(resp=>{
            this.setState({exceptions: resp})
        })
    }
  render() {
    const {exceptions, selected} = this.state;
    const btns = [
       
        {
            title: "Accepted",
            id: "1"
        },
        {
            title: "Rejected",
            id: "2"
        },
    ]
    // console.log(exceptions)
    return (
        <div className='w-full pl-[27px] pr-[27px]'>
            <Header title="ex_excp" />
            <div className='mt-[30px] flex justify-between items-center max-[1100px]:mt-[120px] max-[800px]:mt-[180px]'>
                <HeaderButtons  onClick={(data, index)=>this.changeDataBtn(data, index)} buttons={btns} selected={selected} />
            </div>
            <div className='mt-[30px] grid-full-250'>
                {
                    exceptions?.map((data, i)=>{
                        return(
                            <div key={i} className='bg-white shadow p-[10px] rounded-[10px]'>
                                <a href={data?.image_full_url} target='_blank'>
                                    <img src={data?.image_full_url} className='w-full rounded-[10px] h-[150px]' />
                                </a>
                                <ul className='flex flex-col gap-[10px] mt-[10px]'>
                                    <CardLine svg={<CardUserSvg />} title={data?.user?.full_name} />
                                    <CardLine svg={<CardEmailSvg />} title={data?.user?.email} />
                                    <CardLine svg={<CardDateSvg />} title={moment(data?.created_at).format("DD/MM/YYYY")} />
                                    <CardLine svg={<CardCheatingSvg />} title={data?.user?.company?.name} />
                                </ul>
                                <div className='flex justify-between mt-[10px]'>
                                    {
                                        data?.status==1?
                                        <button  onClick={this.changeStatus.bind(this, data?.id, "2")} className='bg-red h-[21px] pl-[3px] pr-[3px] text-white rounded-[3px] text-[10px]'>Take out</button>:null
                                    }
                                </div>
                            </div>
                        )
                    })
                }
           </div>
        </div>
    )
  }
}
const mapStateToProps = (state) =>({

});
const mapDispatchToProps = {getComapnyExamException, insertNewData}
export default connect(mapStateToProps, mapDispatchToProps)(CompanyExamException)
